import React, { useEffect, useState, useMemo } from 'react';
import { Layout } from 'antd';
import './MapView.less';
import {
  selectMapContent,
  selectSelectedCar,
  selectUser,
  selectRentalData,
  isRentalOngoing,
  selectBookings,
  withTextLocalizer,
  msToHMS,
  gasStations,
  selectCircleId,
  switchAccessToCircle,
  getBrandName,
  setSelectedCar,
  selectOpenedModal,
  setOpenedModal,
  setDefaultTab,
  selectShowHomeWT,
  selectPreSignupBooking,
  selectIsEmbedded,
} from 'getaway-data-layer';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MapView from './MapView';
import './MapContainer.styles.less';
import BrandIcon from '../../components/BrandIcon/BrandIcon';
import {
  closeNav,
  getInCompleteIconStyles,
  getProfileIconStyles,
  onSwipeLeft,
  openNav,
} from './map.helpers';
import {
  GA_TITLES,
  isIncompleteProfile,
  isMobileBrowser,
  navigateTo,
  RENTAL_UI_UPDATER_DURATION,
} from '../../../utils';
import { mapProfile } from '../../../assets/brandImages';
import CarsCarousel from '../../components/Carousel/CarsCarousel';
import CarProfileContainer from '../../components/CarProfile/CarProfileContainer';
import MapFilterBar from '../../components/MapFilterBar/MapFilterBar';
import MapCardContainer from '../MapCard/MapCardContainer';
import HelpAction from '../../components/HelpAction/HelpAction';
import SideMenuContainer from '../../components/SideMenu/SideMenuContainer';
import MapFilterContainer from './MapFilter/MapFilterContainer';
import GasStationContainer from '../../components/GasStation/GasStationContainer';
import config from '../../../env.config';
import HomeContainer from '../Home/HomeContainer';
import { getCardHeaderHeight } from '../../components/DraggableCard/DraggableCard';
import SideCarousel from '../../components/SideCarousel/SideCarousel';
import RentalWalkThroughContainer from '../Rental/RentalWalkThrough/RentalWalkThroughContainer';
import WarningTopBar from '../../components/WarningTopBar/WarningTopBar';
import EmbeddedCard from './EmbeddedCard/EmbeddedCard';

type Props = { localize: any };

const MapContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedCar = useSelector(selectSelectedCar) as any;
  const rentalData = useSelector(selectRentalData) as any;
  const user = useSelector(selectUser) as any;
  const mapContent = useSelector(selectMapContent) as any;
  const inCompleteProfile = isIncompleteProfile(user);
  const bookings = useSelector(selectBookings) as any;
  const preSignupBooking = useSelector(selectPreSignupBooking);
  const circleId = useSelector(selectCircleId) as any;
  const showHomeWT = useSelector(selectShowHomeWT) as boolean;
  const isMapFilterOpened = useSelector(selectOpenedModal) === 'MapFilterModal';
  const [bookingCountdown, setBookingCountdown] = useState(null) as any;
  const isEmbedded = useSelector(selectIsEmbedded);

  const selectedGasStation = useMemo(
    () => gasStations.find((station: any) => station.id === mapContent.id),
    [mapContent]
  );

  let selectedCircle = null;
  if (user && user?.circles) {
    selectedCircle = user.circles.find((circle: any) => circle.id === circleId);
  }
  const disabled =
    user?.circles?.length === 1 || (rentalData && isRentalOngoing(rentalData));

  const activeBookingsList =
    bookings
      ?.filter((booking: any) =>
        ['confirmed', 'offered', 'waiting'].includes(booking.status)
      ) // @ts-ignore
      ?.sort((a, b) => new Date(a?.from) - new Date(b?.from)) || null;

  const onClickActiveBooking = (booking: any) => {
    dispatch(setSelectedCar(null));
    if (booking?.status === 'offered' && booking?.bookingType !== 'express') {
      navigateTo(history, `/booking/consent/${activeBookingsList[0].id}`);
    } else {
      navigateTo(history, `/booking/${activeBookingsList[0].id}`);
    }
  };

  const onClickPreSignupBooking = () => {
    navigateTo(history, '/booking/confirmation');
  };

  const onPressCirclesDropdown = async () => {
    const options = user?.circles.map((circle: any) => ({
      title:
        circle.name ||
        localize(`brand.${getBrandName(config.brand.xBrandId!)}.name`),
      action: () => dispatch(switchAccessToCircle(circle.id)),
    }));
    const { showAlertWithOptions } = await import(
      'getaway-data-layer/src/helpers/showAlert'
    );
    showAlertWithOptions('', '', options);
  };

  const onClickLogin = () => {
    dispatch(setSelectedCar(null));
    navigateTo(history, '/login');
  };

  const onClickSignup = () => {
    dispatch(setSelectedCar(null));
    navigateTo(history, '/signup');
  };

  const onClickShowAvailability = (event: any) => {
    event.stopPropagation();
    dispatch(setDefaultTab(1));
    dispatch(setOpenedModal('CarProfileModal'));
  };

  useEffect(() => {
    let countdownUpdaterId: any;
    const activeBooking = activeBookingsList?.[0] || preSignupBooking;
    onSwipeLeft();
    if (activeBooking && !bookingCountdown) {
      setBookingCountdown(
        msToHMS(
          // @ts-ignore
          Math.abs(new Date(activeBooking?.from) - new Date()),
          localize
        )
      );
      countdownUpdaterId = setInterval(() => {
        setBookingCountdown(
          msToHMS(
            // @ts-ignore
            Math.abs(new Date(activeBooking?.from) - new Date()),
            localize
          )
        );
      }, RENTAL_UI_UPDATER_DURATION);
    }

    if (!isMobileBrowser() && isRentalOngoing(rentalData)) {
      navigateTo(history, '/mobile');
    }
    return () => {
      if (countdownUpdaterId) {
        clearInterval(countdownUpdaterId);
      }
    };
  }, [activeBookingsList.length, preSignupBooking, rentalData]);

  return (
    <Layout>
      {isEmbedded && <EmbeddedCard />}
      {!isEmbedded && (
        <>
          {user && location.pathname.includes('/map') && (
            <div
              onClick={() => {
                openNav(history);
                if (!isMobileBrowser()) {
                  dispatch(setSelectedCar(null));
                }
              }}
            >
              {inCompleteProfile && (
                <BrandIcon
                  name="iconIncomplete"
                  style={{
                    ...getInCompleteIconStyles(selectedCar),
                    top:
                      activeBookingsList?.length || preSignupBooking
                        ? '45px'
                        : '20px',
                  }}
                />
              )}
              <img
                alt="mapProfile"
                src={mapProfile}
                // @ts-ignore
                style={{
                  ...getProfileIconStyles(selectedCar),
                  top:
                    activeBookingsList?.length || preSignupBooking
                      ? '45px'
                      : '20px',
                }}
              />
            </div>
          )}
          {user?.circles &&
            user.circles.length > 0 &&
            selectedCircle &&
            (user.circles.length === 1 &&
            user.circles[0].circleType === 'brand' ? null : (
              <div
                className="circleDropdown"
                // @ts-ignore
                style={{
                  top:
                    activeBookingsList?.length || preSignupBooking
                      ? '50px'
                      : '25px',
                }}
                // @ts-ignore
                onClick={disabled ? null : onPressCirclesDropdown}
              >
                <div className="circleTitle">
                  {selectedCircle?.name ||
                    localize(
                      `brand.${getBrandName(config.brand.xBrandId!)}.name`
                    )}
                </div>
                {!disabled && <div className="circleArrow" />}
              </div>
            ))}
        </>
      )}
      {!isEmbedded && <HelpAction name="mapHelp" />}
      {!isEmbedded && <WarningTopBar />}
      {!isEmbedded &&
        user &&
        (activeBookingsList?.length > 0 || preSignupBooking) &&
        bookingCountdown && (
          <div
            className="map-top-gradiant-container"
            onClick={() =>
              preSignupBooking
                ? onClickPreSignupBooking()
                : onClickActiveBooking(activeBookingsList[0])
            }
          >
            <img
              alt="add"
              src="/images/gradientBackgroundTop.png"
              className="map-top-gradiant"
            />
            <div className="map-top-text">{`${localize(
              preSignupBooking
                ? 'booking.map.pre.signup'
                : 'booking.map.bar.text'
            )} ${bookingCountdown}`}</div>
          </div>
        )}

      {!isEmbedded && user && <SideMenuContainer />}

      <div id="main">
        <Layout>
          <MapView
            localize={localize}
            closeNav={() => closeNav()}
            activeBookingsList={activeBookingsList}
            onClickShowAvailability={onClickShowAvailability}
          />
        </Layout>

        {!isEmbedded &&
          (['availableCar', 'unavailableCar'].includes(mapContent.mapType) ||
            ['selectedAvailableCar', 'selectedUnavailableCar'].includes(
              mapContent.mapType
            )) &&
          !(rentalData && isRentalOngoing(rentalData)) &&
          selectedCar &&
          isMobileBrowser() && (
            <div className="map-carousel-container">
              <CarsCarousel />
            </div>
          )}
        {!isEmbedded &&
          (['availableCar', 'unavailableCar'].includes(mapContent.mapType) ||
            ['selectedAvailableCar', 'selectedUnavailableCar'].includes(
              mapContent.mapType
            )) &&
          !(rentalData && isRentalOngoing(rentalData)) &&
          selectedCar &&
          !isMobileBrowser() && (
            <div className="map-side-carousel-container">
              <SideCarousel />
            </div>
          )}
        {!isEmbedded &&
          user &&
          ['gasStation', 'rentalNearestGasStation'].includes(
            mapContent.mapType
          ) &&
          selectedGasStation && (
            <div
              className="map-gas-station-container"
              style={
                isRentalOngoing(rentalData)
                  ? { bottom: getCardHeaderHeight(mapContent?.cardType) + 65 }
                  : {}
              }
            >
              <GasStationContainer gasStation={selectedGasStation} />
            </div>
          )}
        {!isEmbedded && !user && (
          <button
            id={GA_TITLES.AUTH.OPEN_LOGIN}
            className="home-login-btn"
            type="submit"
            style={
              process.env.REACT_APP_X_BRAND_ID === '000000000000000000000003'
                ? {
                    color: '#0077BE',
                    backgroundColor: 'white',
                    fontFamily: 'AvenirNext-Medium, serif',
                  }
                : {}
            }
            onClick={onClickLogin}
          >
            {localize('home.walkthrough.login')}
          </button>
        )}
        {!isEmbedded && !user && !isMobileBrowser() && !selectedCar && (
          <div
            id={GA_TITLES.AUTH.OPEN_SIGNUP}
            className="home-signup-bar"
            onClick={onClickSignup}
          >
            {localize('signup.header')}
          </div>
        )}
        {!isEmbedded && <CarProfileContainer />}
        {!isEmbedded &&
          ((selectedCar && !isMobileBrowser()) || user || isMobileBrowser()) &&
          location.pathname.includes('/map') &&
          !isMapFilterOpened && <MapFilterBar />}
        {!isEmbedded && <MapFilterContainer />}
        {!isEmbedded && user && isMobileBrowser() && <MapCardContainer />}
        {!isEmbedded && isMobileBrowser() && showHomeWT && <HomeContainer />}
        {!isEmbedded && <RentalWalkThroughContainer />}
        {!isEmbedded &&
          !isMobileBrowser() &&
          !location.pathname.includes('/map') && (
            <div className="map-backdrop" />
          )}
      </div>
    </Layout>
  );
};

export default withTextLocalizer(MapContainer);
