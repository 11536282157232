import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectDialog, clearDialog } from 'getaway-data-layer';
import {
  List,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Button,
  Divider,
} from '@mui/material';
import MUIDialog from '@mui/material/Dialog';
import './Dialog.less';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export type DialogOption = {
  title: string | React.ReactElement;
  action?: () => void;
};

const Dialog = () => {
  const dispatch = useDispatch();
  const selectedDialog = useSelector(selectDialog) as any;

  const handleYesAction = () => {
    if (selectedDialog.yesAction instanceof Function) {
      selectedDialog.yesAction();
    }
    dispatch(clearDialog());
  };

  const handleNoAction = () => {
    if (selectedDialog?.noAction) {
      selectedDialog.noAction();
    }
    dispatch(clearDialog());
  };

  const handleClose = () => {
    dispatch(clearDialog());
    handleNoAction();
  };

  return (
    selectedDialog && (
      <MUIDialog
        open={!!selectedDialog}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{selectedDialog.title}</DialogTitle>
        {selectedDialog.message && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {selectedDialog.message}
            </DialogContentText>
          </DialogContent>
        )}
        {selectedDialog?.actionComponent && selectedDialog?.actionComponent()}
        {selectedDialog?.options && <Divider />}
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          {selectedDialog?.options ? (
            <List
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              {selectedDialog?.options?.map((option: DialogOption) => (
                <div
                  className="dialog-list-action"
                  key={option.title.toString()}
                  onClick={
                    option?.action
                      ? () => {
                          // @ts-ignore
                          option.action();
                          handleClose();
                        }
                      : handleClose
                  }
                >
                  {option.title}
                </div>
              ))}
            </List>
          ) : (
            <div className="dialog-button-wrapper">
              {selectedDialog?.yesActionText && (
                <Button
                  style={selectedDialog?.isDestructive ? { color: 'red' } : {}}
                  onClick={handleYesAction}
                >
                  {selectedDialog.yesActionText}
                </Button>
              )}
              <Button
                style={
                  selectedDialog?.isDestructive ? { fontWeight: 'bold' } : {}
                }
                onClick={handleNoAction}
              >
                {selectedDialog.noActionText}
              </Button>
            </div>
          )}
        </DialogActions>
      </MUIDialog>
    )
  );
};

export default Dialog;
