import React from 'react';
import './Card.less';

type Props = {
  hideShadow?: boolean;
  children: any;
};

const Card = ({ hideShadow, children }: Props) => {
  return (
    <div
      className="card-container"
      style={hideShadow ? { boxShadow: 'none' } : {}}
    >
      {children}
    </div>
  );
};

Card.defaultProps = {
  hideShadow: false,
  onClick: null,
};

export default Card;
