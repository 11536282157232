import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  withTextLocalizer,
  LocalizePropType,
  selectUser,
  updateUser,
  selectAuth,
  setNotification,
  APP,
} from 'getaway-data-layer';
import UpdateEmailView from './UpdateEmailView';
import { useFormik } from 'formik';
import { recreateAuth } from 'getaway-data-layer/src/redux/store/userData/user/actions';
import {
  UpdateEmailModal,
  UpdateEmailSchema,
} from '../../../../validation/updateEmail.schema';
import { GA_TITLES, reportGAEvent } from '../../../../utils';

type Props = {
  localize: LocalizePropType;
};

interface Values {
  email: string;
  oldEmail: string;
}

const UpdateEmailContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser) as any;
  const basicAuth = useSelector(selectAuth) as any;

  const onSubmit = ({ email, oldEmail }: Values) => {
    dispatch(
      updateUser({ email: email }, (newUser) => {
        dispatch(recreateAuth(basicAuth, oldEmail, newUser.email));
        dispatch(
          setNotification({
            message: localize('email.update.success'),
            type: APP.NOTIFICATION_TYPE.INFO,
            localize: true,
          })
        );
        history.goBack();
        reportGAEvent(GA_TITLES.ON_BOARDING.UPDATE_EMAIL_SUBMITTED);
      })
    );
  };

  const onClose = () => {
    history.goBack();
  };

  const UpdateEmailFormik = useFormik({
    initialValues: UpdateEmailModal(user.email),
    validationSchema: UpdateEmailSchema(localize),
    onSubmit,
  });

  return (
    <UpdateEmailView
      localize={localize}
      email={user.email}
      formik={UpdateEmailFormik}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(UpdateEmailContainer);
