import React from 'react';
import {
  LocalizePropType,
  formatMoneyEuro,
  truncateText,
  isFreeInsurance,
  InsuranceData,
  getBrandColor,
  selectIsLoadingEstimation,
} from 'getaway-data-layer';
import Hyperlink from 'react-native-hyperlink';
import ListItem from '../../components/ListItem/ListItem';
import './ExpandableEstimatedCost.style.less';
import { useSelector } from 'react-redux';

type Props = {
  localize: LocalizePropType;
  initiallyOpened?: boolean;
  estimatedCost?: any;
  insurancesData?: Array<InsuranceData>;
  formatMoneyEuro?: (value?: number) => string;
  onPressPriceInfo: () => void;
};

const ExpandableEstimatedCost = ({
  localize,
  estimatedCost,
  insurancesData,
  initiallyOpened,
  onPressPriceInfo,
}: Props) => {
  const [opened, setOpened] = React.useState(initiallyOpened);
  const isLoadingEstimation = useSelector(selectIsLoadingEstimation);

  return estimatedCost?.totalCost || isLoadingEstimation ? (
    <div>
      <>
        <ListItem
          title={localize('booking.confirm.cost')}
          greyTextColor
          value={formatMoneyEuro(estimatedCost?.totalCost)}
          brandValueColor
          onClick={() => setOpened(!opened)}
          rightIcon={opened ? 'arrowUp' : 'arrowDown'}
          rightIconSize={12}
          showSeparator={false}
          isLoading={isLoadingEstimation}
        />
      </>
      {opened && estimatedCost?.invoiceItems && (
        <>
          {[...estimatedCost.invoiceItems]
            ?.sort((a, b) => b?.amount * b?.price - a?.amount * a?.price)
            ?.map((item) => (
              <ListItem
                key={item.code}
                containerStyles={{ marginLeft: 16, height: 40, marginTop: 0 }}
                title={truncateText(
                  `${item?.amount}x ${item?.description}`,
                  28
                )}
                greyTextColor
                value={formatMoneyEuro(item?.amount * item?.price)}
                valueStyles={{ color: 'black' }}
                rightIconSize={12}
                showSeparator={false}
              />
            ))}
          {estimatedCost?.includedKM !== undefined &&
            estimatedCost?.includedKM != 0 && (
              <ListItem
                containerStyles={{ marginLeft: 16, height: 40, marginTop: 0 }}
                title={truncateText(
                  `${estimatedCost?.includedKM}x ${localize(
                    'booking.cost.includedkm'
                  )}`,
                  28
                )}
                greyTextColor
                value={formatMoneyEuro(0)}
                valueStyles={{ color: 'black' }}
                rightIconSize={12}
                showSeparator={false}
              />
            )}
          <ListItem
            containerStyles={{ marginLeft: 16, height: 40, marginTop: 0 }}
            title={truncateText(
              localize(
                `insurance.fee_item.description`,
                insurancesData?.find((item) => isFreeInsurance(item?.dailyRate))
                  ?.deductibleAmount / 100
              ),
              32
            )}
            value={formatMoneyEuro(0)}
            greyTextColor
            valueStyles={{ color: 'black' }}
            rightIconSize={12}
            showSeparator={false}
          />
          <ListItem
            containerStyles={{ marginLeft: 16, height: 40, marginTop: 0 }}
            value={formatMoneyEuro(estimatedCost?.totalCost)}
            greyTextColor
            valueStyles={{ color: 'black', alignSelf: 'right' }}
            rightIconSize={12}
            showSeparator={false}
          />
          <div className="expandable-cost-reserved-card-linkContainer">
            <Hyperlink
              linkStyle={{ color: getBrandColor() }}
              linkText={(url) =>
                url === localize('booking.cost.footnote.url')
                  ? localize('booking.cost.footnote.url.text')
                  : url
              }
              onPress={() => {
                onPressPriceInfo();
              }}
            >
              <div>
                {localize(
                  'booking.cost.footnote',
                  localize('booking.cost.footnote.url')
                )}
              </div>
            </Hyperlink>
          </div>
        </>
      )}
      <div className="expandable-cost-reserved-card-separator" />
    </div>
  ) : null;
};

ExpandableEstimatedCost.defaultProps = { initiallyOpened: false };

export default ExpandableEstimatedCost;
