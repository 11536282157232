import { bookingClient } from '../../../clients/bookingClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../../networkStatus';
import {
  CLIENT_TYPE,
  Dispatch,
  GetState,
  PreSignupBooking,
} from '../../../../@types';
import { DriverBookingData, Location } from '../../../../@types';
import TYPES from '../../../../@types/redux/store/BookingsTypes';
import { setNotification } from '../../ui/notifications';
import { APP } from '../../../../enums';
import { AnalyticsManager } from '../../../../native/analyticsManager';
import { clearFilter } from '../../carsSearch';
import { setAppRated } from '../../appData/common';
import { LocalizePropType } from '../../../../enhancers/withTextLocalizer';
import { isBeforeNextWeek } from '../../../../helpers/dateHelpers';
import { isWeb } from '../../../../helpers/platformHelpers';
import {
  UpdateBooking,
  AppendBookings,
  SetPreSignupBooking,
} from '../../../../@types';
import { showBlockPaymentDialog } from '../../../../helpers/reserveCarHelpers';
import { showAppReviewDialog } from '../../../../helpers/dialogsHelpers';

export const setBookings = (bookings: Array<DriverBookingData>) => ({
  type: TYPES.SET_BOOKINGS,
  payload: {
    bookings,
  },
});

export const setPreSignupBooking = (
  booking: PreSignupBooking
): SetPreSignupBooking => ({
  type: TYPES.SET_PRE_SIGNUP_BOOKING,
  payload: {
    booking,
  },
});

export const appendBookings = (
  bookings: Array<DriverBookingData>
): AppendBookings => ({
  type: TYPES.APPEND_BOOKINGS,
  payload: {
    bookings,
  },
});

export const updateBookingRT = (booking: DriverBookingData): UpdateBooking => ({
  type: TYPES.UPDATE_BOOKING,
  payload: {
    booking,
  },
});

export const getBookings =
  (afterId: string | null | undefined = null, includeHistory = true) =>
  async (dispatch: Dispatch) => {
    const clientType = afterId
      ? CLIENT_TYPE.BOOKING_CLIENT.GET_MORE_DRIVER_BOOKINGS
      : CLIENT_TYPE.BOOKING_CLIENT.GET_DRIVER_BOOKINGS;
    dispatch(setNetworkActivity(clientType));
    // $FlowFixMe
    const { notModified, data, error } = await bookingClient.getDriverBookings(
      afterId,
      10,
      includeHistory
    );

    if (error) {
      dispatch(setNetworkError(clientType, error));
    } else {
      if (!!data && !notModified) {
        if (afterId) {
          dispatch(appendBookings(data));
        } else {
          dispatch(setBookings(data));
        }
      }

      dispatch(setNetworkSuccess(clientType));
    }
  };

export const getBooking =
  (bookingId: string, errorCallback?: () => void) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { bookings } = getState().userData.bookings;
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.GET_DRIVER_BOOKING));
    // $FlowFixMe
    const { notModified, data, error } = await bookingClient.getDriverBooking(
      bookingId
    );

    if (error && error.detail.status !== 404) {
      dispatch(
        setBookings(
          [...bookings]?.filter((booking) => booking.id !== bookingId)
        )
      );

      if (typeof errorCallback === 'function') {
        errorCallback();
      }
    } else if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.GET_DRIVER_BOOKING, error)
      );
    } else {
      if (!!data && !notModified) {
        dispatch(
          setBookings(
            [...bookings]?.map((booking) =>
              booking.id === data.id ? data : booking
            )
          )
        );
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.GET_DRIVER_BOOKING)
      );
    }
  };

export const startBooking =
  (
    bookingId: string,
    localize: LocalizePropType,
    createBlockPayment: boolean | null | undefined = null,
    handleViolationsCallback: (violations: any) => void = () => {},
    successCallback: () => void = () => {}
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { bookings } = getState().userData.bookings;
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.START_BOOKING));
    // $FlowFixMe
    const { notModified, data, error } = await bookingClient.startBooking(
      bookingId
    );

    if (error && error.detail.status === 400) {
      const isBlockPaymentNeeded = Object.keys(
        error.detail.data.violations
      ).find(
        (violation) =>
          violation === 'api_error.block_payment_parameter_missing' ||
          violation === 'api_error.block_payment_parameter_missing.paypal'
      );

      if (createBlockPayment === null && isBlockPaymentNeeded) {
        showBlockPaymentDialog(
          localize,
          () => {
            dispatch(
              startBooking(
                bookingId,
                localize,
                true,
                handleViolationsCallback,
                successCallback
              )
            );
          },
          isBlockPaymentNeeded
        );
      }
      if (isWeb() && !isBlockPaymentNeeded) {
        handleViolationsCallback(error.detail.data.violations);
      }
    } else if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.START_BOOKING, error)
      );
    } else {
      if (!!data && !notModified) {
        dispatch(
          setBookings(
            [...bookings]?.map((booking) =>
              booking.id === data.id ? data : booking
            )
          )
        );
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.START_BOOKING));
    }
  };

export const getMoreBookings =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const existingBookings = getState().userData.bookings.bookings;
    const lastTripId =
      existingBookings.length > 0
        ? existingBookings[existingBookings.length - 1].id
        : null;
    dispatch(getBookings(lastTripId));
  };

export const bookCar =
  (
    localize: LocalizePropType,
    from: Date,
    to: Date,
    location: Location | null,
    insuranceId?: string,
    orderedOptions: any = null,
    mileage?: number,
    carId?: string,
    confirmed: boolean = true,
    createBlockPayment: boolean | null | undefined = null,
    callbackFunction?: () => any,
    successCallback?: (data: any) => any,
    handleViolationsCallback: (violations: any) => void = () => {}
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { circleId } = getState().carsSearch;
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR));
    const bookingData: any = {
      from: from.toUTCString(),
      to: to.toUTCString(),
      location: `${location?.latitude}, ${location?.longitude}`,
      circleId,
    };

    if (mileage) {
      bookingData.mileage = mileage;
    }

    if (carId) {
      bookingData.carId = carId;
    }

    if (insuranceId) {
      bookingData.insuranceId = insuranceId;
    }

    if (confirmed) {
      bookingData.confirmed = confirmed;
    }

    if (createBlockPayment !== null) {
      bookingData.createBlockPayment = createBlockPayment;
    }

    bookingData.orderedOptions = orderedOptions || [];
    // $FlowFixMe
    const { data, error } = await bookingClient.bookCar(bookingData);

    if (error) {
      if (error.detail.status === 400) {
        const isBlockPaymentNeeded = Object.keys(
          error.detail.data.violations
        ).find(
          (violation) =>
            violation === 'api_error.block_payment_parameter_missing' ||
            violation === 'api_error.block_payment_parameter_missing.paypal'
        );

        if (createBlockPayment === null && isBlockPaymentNeeded) {
          showBlockPaymentDialog(
            localize,
            () => {
              dispatch(
                bookCar(
                  localize,
                  from,
                  to,
                  location,
                  insuranceId,
                  undefined,
                  mileage,
                  carId,
                  confirmed,
                  isBeforeNextWeek(from),
                  callbackFunction,
                  successCallback
                )
              );
            },
            isBlockPaymentNeeded
          );
        }
        if (isWeb() && !isBlockPaymentNeeded) {
          handleViolationsCallback(error.detail.data.violations);
        }
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.CREATING_BOOKING,
        properties: {
          result: APP.ANALYTICS.OPTIONS.FAILED,
        },
      });
      dispatch(setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR, error));
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR));
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.CREATING_BOOKING,
        properties: {
          result: APP.ANALYTICS.OPTIONS.SUCCESS,
        },
      });

      if (data) {
        const { bookings } = getState().userData.bookings;
        const { appRated } = getState().appData.common;
        const { bundleId, appleAppId } = getState().config;
        dispatch(clearFilter());
        dispatch(setBookings([...bookings, data]));

        if (typeof successCallback === 'function') {
          successCallback(data);
        }

        if (!data?.paymentLinkUrl) {
          setTimeout(() => {
            if (!appRated) {
              showAppReviewDialog(localize, bundleId, appleAppId, () => {
                dispatch(setAppRated(true));
              });
            }
          }, 6000);
        }
      }
    }
  };

export const confirmBooking =
  (
    localize: LocalizePropType,
    bookingId: string,
    circleId: string,
    insuranceId: string,
    orderedOptions: any = null,
    from: Date,
    createBlockPayment: boolean | null | undefined = null,
    callbackFunction?: () => any,
    successCallback?: (data: any) => any,
    handleViolationsCallback: (violations: any) => void = () => {}
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.CONFIRM_BOOKING));

    const bookingData: any = {
      from: from.toUTCString(),
    };

    if (insuranceId) {
      bookingData.insuranceId = insuranceId;
    }

    if (circleId) {
      bookingData.circleId = circleId;
    }

    if (createBlockPayment !== null) {
      bookingData.createBlockPayment = createBlockPayment;
    }

    bookingData.orderedOptions = orderedOptions || [];

    // $FlowFixMe
    const { data, error } = await bookingClient.confirmBooking(
      bookingId,
      bookingData
    );

    if (error) {
      if (error.detail.status === 400) {
        const isBlockPaymentNeeded = Object.keys(
          error.detail.data.violations
        ).find(
          (violation) =>
            violation === 'api_error.block_payment_parameter_missing' ||
            violation === 'api_error.block_payment_parameter_missing.paypal'
        );

        if (createBlockPayment === null && isBlockPaymentNeeded) {
          showBlockPaymentDialog(
            localize,
            () => {
              dispatch(
                confirmBooking(
                  localize,
                  bookingId,
                  circleId,
                  insuranceId,
                  orderedOptions,
                  from,
                  isBeforeNextWeek(from),
                  callbackFunction,
                  successCallback
                )
              );
            },
            isBlockPaymentNeeded
          );
        }
        if (isWeb() && !isBlockPaymentNeeded) {
          handleViolationsCallback(error.detail.data.violations);
        }
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.CONFIRM_BOOKING, error)
      );
    } else {
      if (data) {
        const { bookings } = getState().userData.bookings;
        dispatch(clearFilter());
        dispatch(setBookings([...bookings, data]));

        if (typeof successCallback === 'function') {
          successCallback(data);
        }

        if (!data?.paymentLinkUrl) {
          dispatch(
            setNotification({
              message: 'notification.booking.success',
              type: APP.NOTIFICATION_TYPE.CUSTOM,
              actionText: 'notification.booking.rentals',
              actionCallback: callbackFunction,
              duration: 5000,
            })
          );
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.CONFIRM_BOOKING));
    }
  };

export const cancelBooking =
  (bookingId: string, successCallback: () => void = () => {}) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.CANCEL_BOOKING_REQUEST)
    );
    // $FlowFixMe
    const { error, data } = await bookingClient.cancelBookingRequest(bookingId);

    if (error && error.detail.status !== 404) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.BOOKING_CLIENT.CANCEL_BOOKING_REQUEST,
          error
        )
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (typeof successCallback === 'function') {
        successCallback();
      }

      if (data) {
        const { bookings } = getState().userData.bookings;
        dispatch(
          setBookings(
            [...bookings]?.map((booking) =>
              booking.id === data.id ? data : booking
            )
          )
        );
      }

      dispatch(
        setNotification({
          message: 'booking.driver.cancel.success',
          type: APP.NOTIFICATION_TYPE.INFO,
        })
      );
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.CANCEL_BOOKING_REQUEST)
      );
    }
  };

export const acceptBookingOffer =
  (bookingId: string, carId: string) => async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.ACCEPT_BOOKING_OFFER)
    );
    // $FlowFixMe
    const { data, error } = await bookingClient.acceptBookingOffer(
      bookingId,
      carId
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.ACCEPT_BOOKING_OFFER, error)
      );

      if (error.detail.data && error.detail.data.violations) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (data) {
        dispatch(setBookings([data]));
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.ACCEPT_BOOKING_OFFER)
      );
    }
  };

export const removeBookingOffer =
  (carId: string, bookingId: string) => async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.REMOVE_BOOKING_OFFER)
    );
    // $FlowFixMe
    const { error } = await bookingClient.removeBookingOffer(carId, bookingId);

    if (error && error.detail.status !== 404) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.REMOVE_BOOKING_OFFER, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(getBookings());
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.REMOVE_BOOKING_OFFER)
      );
      dispatch(
        setNotification({
          message: 'driver.booking.offer.remove.success',
          type: APP.NOTIFICATION_TYPE.INFO,
        })
      );
    }
  };

export const getCarBookings =
  (
    carId: string,
    from: string | Date,
    to: string | Date,
    successCallback: (data: any) => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.GET_CAR_BOOKINGS));
    // $FlowFixMe
    const { notModified, data, error } = await bookingClient.getCarBookings(
      carId,
      from,
      to
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.GET_CAR_BOOKINGS, error)
      );
    } else {
      if (!!data && !notModified) {
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.GET_CAR_BOOKINGS));
    }
  };
export const bookPoi =
  (
    localize: LocalizePropType,
    from: Date,
    to: Date,
    location: Location,
    insuranceId: string,
    acrissCode?: string,
    areaId?: string,
    confirmed: boolean = true,
    createBlockPayment: boolean | null | undefined = null,
    callbackFunction?: () => any,
    successCallback?: (data: any) => any
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.BOOK_POI));
    const bookingData: any = {
      from: from.toUTCString(),
      to: to.toUTCString(),
      location: `${location.latitude}, ${location.longitude}`,
    };

    if (acrissCode) {
      bookingData.acrissCode = acrissCode;
    }

    if (areaId) {
      bookingData.areaId = areaId;
    }

    if (confirmed) {
      bookingData.confirmed = confirmed;
    }

    if (insuranceId) {
      bookingData.insuranceId = insuranceId;
    }

    if (createBlockPayment !== null) {
      bookingData.createBlockPayment = createBlockPayment;
    }

    // $FlowFixMe
    const { data, error } = await bookingClient.bookCar(bookingData);

    if (error) {
      if (error.detail.status === 400) {
        const isBlockPaymentNeeded = Object.keys(
          error.detail.data.violations
        ).find(
          (violation) =>
            violation === 'api_error.block_payment_parameter_missing' ||
            violation === 'api_error.block_payment_parameter_missing.paypal'
        );

        if (createBlockPayment === null && isBlockPaymentNeeded) {
          showBlockPaymentDialog(
            localize,
            () => {
              dispatch(
                bookPoi(
                  localize,
                  from,
                  to,
                  location,
                  insuranceId,
                  acrissCode,
                  areaId,
                  confirmed,
                  isBeforeNextWeek(from),
                  callbackFunction,
                  successCallback
                )
              );
            },
            isBlockPaymentNeeded
          );
        } else {
          Object.keys(error.detail.data.violations).forEach((violation) => {
            dispatch(
              setNotification({
                message: error.detail.data.violations[violation],
                type: APP.NOTIFICATION_TYPE.ERROR,
                localize: false,
              })
            );
          });
        }
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.CREATING_STATION_BOOKING,
        properties: {
          result: APP.ANALYTICS.OPTIONS.FAILED,
        },
      });
      dispatch(setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.BOOK_POI, error));
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.BOOK_POI));
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.CREATING_STATION_BOOKING,
        properties: {
          result: APP.ANALYTICS.OPTIONS.SUCCESS,
        },
      });

      if (data) {
        const { bookings } = getState().userData.bookings;
        dispatch(clearFilter());
        dispatch(setBookings([...bookings, data]));

        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
    }
  };
export const updateBooking =
  (
    bookingId: string,
    extras: Record<string, any>,
    successCallback?: (data: any) => any
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.UPDATE_BOOKING));
    // $FlowFixMe
    const { data, error } = await bookingClient.updateBooking(
      bookingId,
      extras
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.UPDATE_BOOKING, error)
      );
    } else {
      if (data) {
        const { bookings } = getState().userData.bookings;
        dispatch(
          setBookings(
            [...bookings]?.map((booking) =>
              booking.id === data.id ? data : booking
            )
          )
        );

        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.UPDATE_BOOKING));
    }
  };
export const getBookingHandoverData =
  (bookingId: string, successCallback?: (data: any) => any) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.GET_BOOKING_HANDOVER)
    );
    // $FlowFixMe
    const { data, error } = await bookingClient.getBookingHandoverData(
      bookingId
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.GET_BOOKING_HANDOVER, error)
      );
    } else {
      if (data) {
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.GET_BOOKING_HANDOVER)
      );
    }
  };
export const getFleetInsurances =
  (fleetId: string, successCallback?: (data: any) => any) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const { circleId } = getState().carsSearch;
    dispatch(
      setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.GET_FLEET_INSURANCES)
    );
    // $FlowFixMe
    const { data, error } = await bookingClient.getFleetInsurances(
      fleetId,
      circleId
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.GET_FLEET_INSURANCES, error)
      );
    } else {
      if (data) {
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.GET_FLEET_INSURANCES)
      );
    }
  };

export const estimateCost =
  (
    localize: LocalizePropType,
    from: Date,
    to: Date,
    carId?: string,
    circleId?: string,
    successCallback?: (data: any) => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.BOOKING_CLIENT.ESTIMATE_COST));
    const bookingData: any = {
      from: from.toUTCString(),
      to: to.toUTCString(),
      circleId,
    };

    if (carId) {
      bookingData.carId = carId;
    }

    // $FlowFixMe
    const { data, error } = await bookingClient.estimateCost(bookingData);

    if (error) {
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );

      dispatch(
        setNetworkError(CLIENT_TYPE.BOOKING_CLIENT.ESTIMATE_COST, error)
      );
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.BOOKING_CLIENT.ESTIMATE_COST));
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.CREATING_BOOKING,
        properties: {
          result: APP.ANALYTICS.OPTIONS.SUCCESS,
        },
      });

      if (data) {
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
    }
  };
