import React, { useState, useRef } from 'react';
import './ListItem.less';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import BrandIcon from '../BrandIcon/BrandIcon';
import Plate from '../Plate/Plate';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ToggleItem from '../ToggleItem/ToggleItem';
import { Switch } from 'antd';
import { Checkbox } from 'antd';
import {
  getLightBrandColor,
  isSafari,
  LocalizePropType,
  setNotification,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useDispatch } from 'react-redux';
import { isMobileBrowser } from '../../../utils';

type Props = {
  localize: LocalizePropType;
  leftIcon?:
    | 'phone'
    | 'home'
    | 'license'
    | 'email'
    | 'key'
    | 'plus'
    | 'sepa'
    | 'visa'
    | 'paymentSepa'
    | 'paymentMastercard'
    | 'paymentVisa'
    | 'carFuel'
    | 'warning'
    | 'fuelKind'
    | 'fuelCard'
    | 'fuelCopy'
    | 'sandClock'
    | 'sandClockRed'
    | 'pal'
    | 'payin'
    | 'user'
    | 'link'
    | 'gdpr'
    | 'phoneSMS'
    | 'privatePayment'
    | 'businessPayment';
  title?: string;
  titleStyles?: any;
  value?: any;
  rightIcon?:
    | 'arrowRight'
    | 'arrowRightBrand'
    | 'iconIncomplete'
    | 'inProgress'
    | 'navigationArrow'
    | 'trash'
    | 'info'
    | 'arrowUp'
    | 'edit'
    | 'copy'
    | 'arrowDown';
  leftIconSize?: number;
  rightIconSize?: number;
  brandTextColor?: boolean;
  greyTextColor?: boolean;
  brandValueColor?: boolean;
  onClick?: () => void;
  showSeparator?: boolean;
  containerStyles?: any;
  carPlate?: string;
  code?: string;
  leftIconStyles?: any;
  leftItem?: React.ReactElement;
  readOnly?: boolean;
  valueStyles?: any;
  isLoading?: boolean;
  showIncomplete?: boolean;
  descriptionComponent?: React.ReactElement;
  showStandard?: boolean;
  toggleItem?: boolean;
  toggleValue?: boolean;
  onToggleValueChange?: () => void;
  toggleOptionOne?: string;
  toggleOptionTwo?: string;
  switchItem?: boolean;
  switchValue?: boolean;
  onSwitchValueChange?: () => void;
  disableSwitch?: boolean;
  checkboxItem?: boolean;
  onClickCheckbox?: () => void;
  checkboxChecked?: boolean;
  disabled?: boolean;
  enableCopyOnClick?: boolean;
  customCopyValue?: string;
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function isEmptyFunction(func) {
  return (
    !func ||
    func?.toString()?.replace(/\s+/g, '') === '(){}' ||
    func?.toString()?.replace(/\s+/g, '') === '()=>{}'
  );
}

const ListItem = ({
  localize,
  leftIcon,
  title,
  titleStyles,
  value,
  valueStyles,
  rightIcon,
  leftIconSize,
  rightIconSize,
  brandTextColor,
  greyTextColor,
  brandValueColor,
  onClick,
  showSeparator,
  containerStyles,
  carPlate,
  code,
  leftIconStyles,
  leftItem,
  readOnly,
  isLoading,
  showIncomplete,
  descriptionComponent,
  showStandard,
  toggleItem,
  toggleValue,
  onToggleValueChange,
  toggleOptionOne,
  toggleOptionTwo,
  switchItem,
  switchValue,
  onSwitchValueChange,
  disableSwitch,
  checkboxItem,
  onClickCheckbox,
  checkboxChecked,
  disabled,
  enableCopyOnClick,
  customCopyValue,
}: Props) => {
  const dispatch = useDispatch();
  const [copyDialog, setCopyDialog] = useState(false);
  const [pressTimer, setPressTimer] = useState<number | null>(null);
  const longPressDetected = useRef(false);
  const touchMoved = useRef(false);
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);

  let toCopy = null;
  if (customCopyValue) {
    toCopy = customCopyValue;
  } else if (typeof value === 'string') {
    toCopy = value;
  } else if (typeof title === 'string') {
    toCopy = title;
  }

  const copyValue = () => {
    if (enableCopyOnClick && !brandTextColor && isEmptyFunction(onClick)) {
      if (toCopy) {
        try {
          navigator.clipboard.writeText(toCopy);
          dispatch(
            setNotification({ message: localize('wj.value.copied', toCopy) })
          );
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    }
  };

  const handleItemClick = () => {
    if (!isMobileBrowser()) {
      copyValue();
    }
    if (onClick) {
      onClick();
    }
  };

  const handleTouchStart = (event) => {
    if (isMobileBrowser()) {
      longPressDetected.current = false;
      touchMoved.current = false;
      touchStartX.current = event?.touches?.[0]?.clientX;
      touchStartY.current = event?.touches?.[0]?.clientY;

      setPressTimer(
        window.setTimeout(() => {
          if (!touchMoved?.current) {
            longPressDetected.current = true;
          }
        }, 500) // 0.5 second
      );
    }
  };

  const handleTouchMove = (event) => {
    const moveThreshold = 10; // Adjust this value as needed
    const touchX = event?.touches?.[0]?.clientX;
    const touchY = event?.touches?.[0]?.clientY;
    if (
      Math.abs(touchX - touchStartX?.current) > moveThreshold ||
      Math.abs(touchY - touchStartY?.current) > moveThreshold
    ) {
      touchMoved.current = true;
    }
  };

  const handleTouchEnd = () => {
    if (isMobileBrowser() && pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);
      if (longPressDetected.current && !touchMoved.current) {
        if (isSafari) {
          if (
            enableCopyOnClick &&
            !brandTextColor &&
            isEmptyFunction(onClick)
          ) {
            if (toCopy) {
              setCopyDialog(true);
            }
          }
        } else {
          copyValue();
        }
      }
      longPressDetected.current = false;
    }
  };

  const handleDialogClose = () => {
    setCopyDialog(false);
  };

  const handleDialogCopy = () => {
    if (toCopy) {
      copyValue();
    }
    setCopyDialog(false);
  };

  return (
    <div>
      <ListItemButton
        style={{
          ...{ padding: '10px 0', marginTop: '8px' },
          ...containerStyles,
          ...(checkboxItem &&
            checkboxChecked && { backgroundColor: getLightBrandColor() }),
        }}
        onClick={handleItemClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleTouchStart}
        onMouseUp={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onMouseLeave={handleTouchEnd}
        disableRipple={true}
      >
        {leftIcon && (
          <ListItemIcon style={leftIconStyles}>
            <BrandIcon
              id={leftIcon}
              name={leftIcon}
              style={{ width: leftIconSize, height: leftIconSize }}
            />
          </ListItemIcon>
        )}
        {leftItem}

        {descriptionComponent ? (
          <div className="middle-component">
            <ListItemText
              className={
                disabled
                  ? 'list-item-text-disabled'
                  : greyTextColor
                  ? 'list-item-text-grey'
                  : brandTextColor
                  ? 'list-item-text-brand'
                  : 'list-item-text'
              }
              style={descriptionComponent ? { margin: 0 } : titleStyles}
              primary={title}
            />
            {descriptionComponent}
          </div>
        ) : (
          <ListItemText
            className={
              disabled
                ? 'list-item-text-disabled'
                : greyTextColor
                ? 'list-item-text-grey'
                : brandTextColor
                ? 'list-item-text-brand'
                : 'list-item-text'
            }
            style={descriptionComponent ? { margin: 0 } : titleStyles}
            primary={title}
          />
        )}
        {showStandard && <div className="payment-standard">STANDARD</div>}
        {value && (
          <div
            className={
              brandValueColor
                ? 'list-item-value list-item-text-brand'
                : 'list-item-value'
            }
            style={valueStyles}
          >
            {isLoading ? <Spin indicator={loadingIcon} /> : value}
          </div>
        )}
        {!value && isLoading && <Spin indicator={loadingIcon} />}
        {carPlate && (
          <Plate carPlate={carPlate} containerStyles={{ minWidth: 'unset' }} />
        )}
        {toggleItem && (
          <ToggleItem
            onToggleValueChange={onToggleValueChange}
            toggleValue={toggleValue}
            optionOne={toggleOptionOne}
            OptionTwo={toggleOptionTwo}
          />
        )}
        {checkboxItem && (
          <Checkbox
            style={{ marginRight: 4 }}
            checked={checkboxChecked}
            onChange={onClickCheckbox}
          />
        )}
        {switchItem && (
          <Switch
            defaultChecked
            disabled={disableSwitch}
            checked={switchValue}
            onChange={onSwitchValueChange}
          />
        )}
        {code && <div className="code-container">{code}</div>}
        {!readOnly && rightIcon && !isLoading && (
          <>
            <ListItemIcon style={{ minWidth: 'unset' }}>
              {showIncomplete && (
                <BrandIcon
                  name="iconIncomplete"
                  style={{
                    width: 10,
                    height: 10,
                    marginLeft: '10px',
                  }}
                />
              )}
              <BrandIcon
                name={rightIcon}
                style={{
                  width: rightIcon === 'inProgress' ? 18 : rightIconSize,
                  height: rightIcon === 'inProgress' ? 18 : rightIconSize,
                  marginLeft: '10px',
                }}
              />
            </ListItemIcon>
          </>
        )}
      </ListItemButton>
      {showSeparator && <Divider />}

      <Dialog open={copyDialog} onClose={handleDialogClose}>
        <DialogTitle>{localize('wj.copy.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{toCopy}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {localize('wj.copy.yes')}
          </Button>
          <Button onClick={handleDialogCopy} color="primary" autoFocus>
            {localize('wj.copy.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ListItem.defaultProps = {
  leftIconSize: 20,
  rightIconSize: 20,
  brandTextColor: false,
  greyTextColor: false,
  brandValueColor: false,
  leftIcon: null,
  rightIcon: null,
  value: null,
  carPlate: null,
  code: null,
  leftItem: null,
  showSeparator: true,
  containerStyles: {},
  leftIconStyles: {},
  title: '',
  onClick: undefined,
  readOnly: false,
  disabled: false,
  valueStyles: {},
  titleStyles: {},
  isLoading: false,
  verified: false,
  showIncomplete: false,
  descriptionComponent: null,
  showStandard: false,
  toggleItem: false,
  toggleOptionOne: '',
  toggleOptionTwo: '',
  checkboxItem: false,
  onClickCheckbox: null,
  checkboxSelected: false,
  enableCopyOnClick: true,
  customCopyValue: null,
};

export default withTextLocalizer(ListItem);
