import { Dispatch, GetState, SetTariffs, TariffData } from '../../../../@types';
import TYPES from '../../../../@types/redux/store/UserTypes';
import {
  PaymentMethodDataInput,
  SignUpInputData,
  Transactions,
  UpdateUserInputData,
  UserDataOutput,
  RentalHistoryDataList,
  DeviceFingerprintInput,
  ChallengeSolutionInput,
  ChallengeShopperData,
  PaymentRequestData,
  JumioWebDocumentType,
  SetCheckoutPaymentMethods,
} from '../../../../@types';
import { DataNativeBridge } from '../../../../native/dataNativeBridge';
import {
  setNetworkActivity,
  setNetworkError,
  setNetworkSuccess,
} from '../../networkStatus';
import { CLIENT_TYPE } from '../../../../@types';
import { authClient } from '../../../clients/authClient';
import { setNotification } from '../../ui/notifications';
import { APP } from '../../../../enums';
import { userClient } from '../../../clients/userClient';
import { isWeb } from '../../../../helpers/platformHelpers';
import createAuthString from '../../../../helpers/createAuthString';
import {
  SetActivePaymentData,
  SetAuth,
  SetJumioRedirectData,
  SetPaymentPublicKey,
  SetRecoverPaymentAuth,
  SetSignUpCircle,
  SetUser,
} from '../../../../@types';
import { LocalizePropType } from '../../../../enhancers/withTextLocalizer';
import { setCircleCode, setReferralCode } from '../../ui/firstSteps';
import { AnalyticsManager } from '../../../../native/analyticsManager';
import { setFirstSteps } from '../../appData/common';
import { switchAccessToCircle } from '../../carsSearch';
import { setDevicePaired } from '../../device';
import { setMapCircleIdSearch } from '../../carsSearch/Actions';
import {
  setIsIntercomUserRegistered,
  setSupportContacted,
} from '../../appData/common/actions';
import { generateNewAuthString } from '../../../../helpers/createAuthString';
import { setPreSignupBooking } from '../bookings/actions';
import { injectedPersistor } from '../../../ConfigureStore';
import { injectedSentry } from '../../../ConfigureGlobals';
import {
  isFirstStepsCompleted,
  registerIntercomUser,
  showTariffRequiredDialog,
} from '../../../../helpers/userHelpers';
import { downloadZip } from '../../../../helpers/fileHelpers';
import { isFRM, isMymBrand } from '../../../../helpers/brandHelpers';

export const setSignUpCircle = (circleCode: string): SetSignUpCircle => ({
  type: TYPES.SET_SIGN_UP_CIRCLE,
  payload: {
    circleCode,
  },
});

export const setTariffs = (tariffs: TariffData[]): SetTariffs => ({
  type: TYPES.SET_TARIFFS,
  payload: {
    tariffs,
  },
});

export const setCheckoutPaymentMethods = (
  data: any
): SetCheckoutPaymentMethods => ({
  type: TYPES.SET_CHECKOUT_PAYMENT_METHODS,
  payload: {
    data,
  },
});

export const setAuth = (email: string, password: string): SetAuth => ({
  type: TYPES.SET_AUTH,
  payload: {
    basicAuth: createAuthString(email, password),
  },
});

export const setCustomBasicAuth =
  (customAuth: string, callbackFunction?: () => any) =>
  async (dispatch: Dispatch) => {
    await dispatch({
      type: TYPES.SET_AUTH,
      payload: {
        basicAuth: customAuth,
      },
    });
    callbackFunction();
  };

export const recreateAuth = (
  oldAuthResult: string,
  oldEmail: string,
  newEmail: string
): SetAuth => ({
  type: TYPES.SET_AUTH,
  payload: {
    basicAuth: generateNewAuthString(oldAuthResult, oldEmail, newEmail),
  },
});

export const clearAuth =
  (updateNative: boolean = true) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: TYPES.CLEAR_AUTH,
    });
    dispatch(setPreSignupBooking(null));
    dispatch(setIsIntercomUserRegistered(false));

    dispatch(setDevicePaired(true));
    dispatch(setSupportContacted(false));
    injectedPersistor.purge();

    if (updateNative) {
      DataNativeBridge.resetAuth();
    }
  };
export const setUserRedux = (user: UserDataOutput): SetUser => ({
  type: TYPES.SET_USER,
  payload: {
    user,
  },
});
export const setRecoverPaymentAuth = (
  recoverPaymentAuth: boolean
): SetRecoverPaymentAuth => ({
  type: TYPES.SET_RECOVER_PAYMENT_AUTH,
  payload: {
    recoverPaymentAuth,
  },
});
export const setJumioRedirectData = (
  jumioRedirectData: any
): SetJumioRedirectData => ({
  type: TYPES.SET_JUMIO_REDIRECT_DATA,
  payload: {
    jumioRedirectData,
  },
});
export const setUser =
  (user: UserDataOutput) => (dispatch: Dispatch, getState: GetState) => {
    const { user: existingUser, basicAuth } = getState().userData.user;
    const { firstSteps } = getState().appData.common;

    if (user && basicAuth && !user.email!.includes('.archived')) {
      const { version, id, supportedPaymentTypes } = user;
      const userDataChanged = existingUser
        ? existingUser?.version! < version! ||
          existingUser.id !== id ||
          // @ts-ignore
          [...existingUser?.supportedPaymentTypes].sort().join(',') !==
            [...supportedPaymentTypes].sort().join(',') ||
          existingUser?.blocked !== user?.blocked
        : true;

      if (userDataChanged) {
        DataNativeBridge.setUser(user);
        dispatch(setUserRedux(user));

        if (!isWeb()) {
          injectedSentry.setUser({
            id: user.id,
          });
        }
        if (!firstSteps && isFirstStepsCompleted(user)) {
          dispatch(setFirstSteps(true));
          AnalyticsManager.event({
            event: APP.ANALYTICS.EVENT.FINISHED_FIRST_STEPS,
          });
        }
      }
    } else {
      dispatch(clearAuth());
    }
  };
export const updateUser =
  (updateUserInputData: any, callbackFunction?: (data: any) => any) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.UPDATE_USER));
    const { notModified, data, error } = await userClient.updateUser(
      updateUserInputData
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.UPDATE_USER, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else if (error.detail.status === 403) {
        dispatch(clearAuth());
      }
    } else {
      if (!!data && !notModified) {
        dispatch(setUser(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.UPDATE_USER));

      if (typeof callbackFunction === 'function') {
        callbackFunction(data);
      }
    }
  };

export const verifyEmail =
  (
    verificationCode: string,
    localize: LocalizePropType,
    callbackFunction?: (data: any) => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.VERIFY_EMAIL));
    const { notModified, data, error } = await userClient.verifyEmail(
      verificationCode
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.VERIFY_EMAIL, error));
      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else if (error.detail.status === 403) {
        dispatch(clearAuth());
      }
    } else {
      if (!!data && !notModified) {
        dispatch(setUser(data));
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.VERIFY_EMAIL));

      if (typeof callbackFunction === 'function') {
        callbackFunction(data);
      }
    }
  };

export const getGDPR =
  (
    verificationCode: string,
    errorCallbackFunction?: () => any,
    successCallbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.DOWNLOAD_GDPR));
    const { notModified, data, headers, error } = await userClient.getGDPR(
      verificationCode
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.DOWNLOAD_GDPR, error));
      if (typeof errorCallbackFunction === 'function') {
        errorCallbackFunction();
      }
      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      if (!!data && !notModified) {
        await downloadZip(data, headers);

        if (typeof successCallbackFunction === 'function') {
          successCallbackFunction();
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.DOWNLOAD_GDPR));
    }
  };

export const reSendVerificationEmail =
  (successCallback = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.USER_CLIENT.SEND_VERIFICATION_EMAIL)
    );
    const { error } = await userClient.reSendVerificationEmail();

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.SEND_VERIFICATION_EMAIL, error)
      );
      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
      if (
        error.detail.status === 400 &&
        process.env.REACT_APP_STAGE === 'production'
      ) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      } else if (error.detail.status === 403) {
        dispatch(clearAuth());
      }
    } else {
      successCallback();
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.SEND_VERIFICATION_EMAIL)
      );
    }
  };

export const checkReferral =
  (referredBy: string, callbackFunction?: () => any) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.AUTH_CLIENT.CHECK_REFERRAL));
    dispatch(setAuth('', ''));
    const { error } = await authClient.checkReferral({
      referredBy,
      email: '',
      password: '',
      firstName: '',
    });
    let success = false;

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.AUTH_CLIENT.CHECK_REFERRAL, error));

      if (error.detail.status === 400) {
        if (!Object.keys(error.detail.data.violations).includes('referredBy')) {
          success = true;
          dispatch(setReferralCode(referredBy));

          if (typeof callbackFunction === 'function') {
            callbackFunction();
          }
        }
      }
    }

    if (!success) {
      dispatch(
        setNotification({
          message: 'referFriend.code.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    }

    dispatch(clearAuth(false));
    dispatch(setNetworkSuccess(CLIENT_TYPE.AUTH_CLIENT.CHECK_REFERRAL));
  };
export const getUser =
  (
    successCallback: (data: UserDataOutput) => void = () => {},
    failCallback: () => void = () => {}
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.GET_USER));
    const { circleId } = getState().carsSearch;
    const { data, error } = await userClient.get();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.GET_USER, error));
      failCallback();
      if ([401, 403].includes(error.detail.status)) {
        dispatch(clearAuth());
      } else if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (data) {
        dispatch(setUser(data));

        if (!circleId && data.circles?.length! > 0) {
          // @ts-ignore
          dispatch(setMapCircleIdSearch(data?.circles[0].id!));
        }

        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.GET_USER));
    }
  };
export const forgetPassword =
  (email: string, localize: LocalizePropType, successCallBack?: () => void) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.AUTH_CLIENT.FORGET_PASSWORD));
    const { error } = await authClient.forgetPassword(email);

    if (error && error.detail.status !== 404) {
      dispatch(setNetworkError(CLIENT_TYPE.AUTH_CLIENT.FORGET_PASSWORD, error));
      dispatch(
        setNotification({
          message: 'update.password.forgot.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.AUTH_CLIENT.FORGET_PASSWORD));
      dispatch(
        setNotification({
          message: localize('update.password.text', email),
          type: APP.NOTIFICATION_TYPE.INFO,
          localize: false,
        })
      );

      if (typeof successCallBack === 'function') {
        successCallBack();
      }
    }
  };

export const resetPassword =
  (
    email: string,
    password: string,
    token: string,
    localize: LocalizePropType,
    successCallBack?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.AUTH_CLIENT.RESET_PASSWORD));
    const { error } = await authClient.resetPassword(email, password, token);

    if (error && error.detail.status !== 404) {
      dispatch(setNetworkError(CLIENT_TYPE.AUTH_CLIENT.RESET_PASSWORD, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(setNetworkSuccess(CLIENT_TYPE.AUTH_CLIENT.RESET_PASSWORD));
      dispatch(
        setNotification({
          message: localize('reset.password.updated'),
          type: APP.NOTIFICATION_TYPE.INFO,
          localize: false,
        })
      );

      if (typeof successCallBack === 'function') {
        successCallBack();
      }
    }
  };

export const login =
  (
    email: string,
    password: string,
    localize: LocalizePropType | undefined,
    callbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.AUTH_CLIENT.LOGIN));
    dispatch(setAuth(email, password));
    const { notModified, data, error } = await authClient.login();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.AUTH_CLIENT.LOGIN, error));

      if (error.detail.status === 401) {
        const { showAlert } = await import('../../../../helpers/showAlert');

        if (localize) {
          showAlert(
            localize('login.failed.alert.title'),
            localize('login.failed.alert.body'),
            localize('login.forgot.text'),
            () => dispatch(forgetPassword(email, localize)),
            localize('login.failed.again.text')
          );
        }
        // Should be deleted for mobile
        dispatch(
          setNotification({
            message: 'login.failed.alert.body',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      } else if (error.detail.status === 403) {
        dispatch(clearAuth());
      }
    } else {
      if (!!data && !notModified) {
        const { basicAuth } = getState().userData.user;
        const { xBrandId } = getState().config;
        const { supportContacted, brandSettings, isIntercomUserRegistered } =
          getState().appData.common;

        if (basicAuth) {
          DataNativeBridge.setAuth(basicAuth);
        }

        registerIntercomUser(
          brandSettings?.intercomHandlingMode!,
          data,
          basicAuth,
          supportContacted,
          isIntercomUserRegistered,
          (registered) => dispatch(setIsIntercomUserRegistered(registered))
        );
        dispatch(setUser(data));

        if (data.circles && data.circles.length > 0) {
          dispatch(
            switchAccessToCircle(
              isMymBrand(xBrandId) && data?.circles?.[1]?.id
                ? data.circles[1].id!
                : data.circles[0].id!
            )
          );
        }

        if (typeof callbackFunction === 'function') {
          callbackFunction();
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.AUTH_CLIENT.LOGIN));
    }
  };
export const signUp =
  (
    signUpData: SignUpInputData,
    localize: LocalizePropType,
    callbackFunction?: (user: any) => void
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.AUTH_CLIENT.SIGNUP));
    dispatch(setAuth(signUpData.email, signUpData.password));
    const { notModified, data, error } = await authClient.signUp(signUpData);

    if (error) {
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.SIGN_UP,
        properties: APP.ANALYTICS.OPTIONS.FAILED,
      });
      dispatch(setNetworkError(CLIENT_TYPE.AUTH_CLIENT.SIGNUP, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        const { showAlert } = await import('../../../../helpers/showAlert');
        const { violations } = error.detail.data;
        Object.keys(violations).forEach((key) => {
          if (key === 'email') {
            showAlert(
              '',
              violations[key],
              localize('reset'),
              () => dispatch(forgetPassword(signUpData.email, localize)),
              localize('cancel')
            );
            // Should be deleted for mobile
            dispatch(
              setNotification({
                message: violations[key],
                type: APP.NOTIFICATION_TYPE.ERROR,
                localize: false,
              })
            );
          } else {
            dispatch(
              setNotification({
                message: violations[key],
                type: APP.NOTIFICATION_TYPE.ERROR,
                localize: false,
              })
            );
          }
        });
      } else if (error.detail.status === 403) {
        dispatch(clearAuth());
      }
    } else {
      if (!!data && !notModified) {
        const { basicAuth } = getState().userData.user;
        const { supportContacted, brandSettings, isIntercomUserRegistered } =
          getState().appData.common;

        if (basicAuth) {
          DataNativeBridge.setAuth(basicAuth);
        }

        registerIntercomUser(
          brandSettings?.intercomHandlingMode!,
          data,
          basicAuth,
          supportContacted,
          isIntercomUserRegistered,
          (registered) => dispatch(setIsIntercomUserRegistered(registered))
        );
        AnalyticsManager.event({
          event: APP.ANALYTICS.EVENT.SIGN_UP,
          properties: APP.ANALYTICS.OPTIONS.SUCCESS,
        });
        dispatch(setUser(data));

        if (data.circles && data?.circles?.length > 0) {
          dispatch(
            switchAccessToCircle(data.circles[data.circles.length - 1].id!)
          );
        }

        if (typeof callbackFunction === 'function') {
          callbackFunction(data);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.AUTH_CLIENT.SIGNUP));
    }
  };
export const setActivePaymentData = (
  activePaymentData: PaymentRequestData | null | undefined
): SetActivePaymentData => ({
  type: TYPES.SET_ACTIVE_PAYMENT_DATA,
  payload: {
    activePaymentData,
  },
});
export const getPaymentData =
  (id: string, successCallback: (data: any) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_DATA));
    const { notModified, data, error } = await userClient.getPaymentData(id);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_DATA, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (!!data && !notModified) {
        dispatch(setActivePaymentData(data));

        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_DATA));
    }
  };
export const addPayment =
  (
    paymentMethodDataInput: PaymentMethodDataInput,
    successCallback?: (data: any) => any,
    invalidCallback?: (arg: any) => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT));
    const { notModified, data, error } = await userClient.addPayment({
      ...paymentMethodDataInput,
      defaultOne: true,
    });

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT, error));
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.ADD_PAYMENT,
        properties: APP.ANALYTICS.OPTIONS.FAILED,
      });

      if ([404, 401].includes(error.detail.status)) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        const { violations } = error.detail.data;
        Object.keys(violations).forEach((key) => {
          if (
            key === 'api_error.payment.card_validation_attempts_exceeded' ||
            key === 'api_error.payment.paypal_validation_failed'
          ) {
            if (typeof invalidCallback === 'function') {
              invalidCallback(violations[key]);
            }
          } else {
            dispatch(
              setNotification({
                message: violations[key],
                type: APP.NOTIFICATION_TYPE.ERROR,
                localize: false,
              })
            );
          }
        });
      }
    } else {
      AnalyticsManager.event({
        event: APP.ANALYTICS.EVENT.ADD_PAYMENT,
        properties: APP.ANALYTICS.OPTIONS.SUCCESS,
      });

      if (!!data && !notModified) {
        dispatch(setUser(data));

        if (
          data.paymentMethods?.length! > 0 &&
          // @ts-ignore
          data.paymentMethods[0].paymentData &&
          // @ts-ignore
          data.paymentMethods[0].paymentRequestId
        ) {
          // @ts-ignore
          dispatch(getPaymentData(data.paymentMethods[0].paymentRequestId!));
        } else {
          dispatch(setActivePaymentData(null));
        }

        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT));
    }
  };

export const getPaymentMethods =
  (successCallBack: (data: any) => void) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PAYMENTS));
    const { data, error } = await userClient.getPaymentMethods();

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PAYMENTS, error)
      );

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        const { violations } = error.detail.data;
        Object.keys(violations).forEach((key) => {
          dispatch(
            setNotification({
              message: violations[key],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      if (data) {
        successCallBack(data);
        dispatch(setCheckoutPaymentMethods(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PAYMENTS));
    }
  };

export const addPaymentDetails =
  (paymentDetails: any, successCallBack: (data: any) => void) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT_DETAILS));
    const { data, error } = await userClient.addPaymentDetails(paymentDetails);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT_DETAILS, error)
      );

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        const { violations } = error.detail.data;
        Object.keys(violations).forEach((key) => {
          dispatch(
            setNotification({
              message: violations[key],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      if (data) {
        successCallBack(data);
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT_DETAILS));
    }
  };

export const deletePayment =
  (paymentId: string) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.DELETE_PAYMENT));
    const { data, error } = await userClient.deletePayment(paymentId);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.DELETE_PAYMENT, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        const { violations } = error.detail.data;
        Object.keys(violations).forEach((key) => {
          dispatch(
            setNotification({
              message: violations[key],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      if (data) {
        dispatch(setUser(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.DELETE_PAYMENT));
    }
  };

export const deletePhone =
  (
    verificationCode: string,
    errorCallbackFunction: () => any,
    successCallback: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.DELETE_PHONE));
    const { data, error } = await userClient.deletePhone(verificationCode);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.DELETE_PHONE, error));
      if (typeof errorCallbackFunction === 'function') {
        errorCallbackFunction();
      }
      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        const { violations } = error.detail.data;
        Object.keys(violations).forEach((key) => {
          dispatch(
            setNotification({
              message: violations[key],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      if (data) {
        dispatch(setUser(data));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.DELETE_PHONE));
    }
  };

export const updatePayment =
  (
    paymentId: string,
    paymentData: any,
    successCallback: () => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.UPDATE_PAYMENT));
    const { data, error } = await userClient.updatePayment(
      paymentId,
      paymentData
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.UPDATE_PAYMENT, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        const { violations } = error.detail.data;
        Object.keys(violations).forEach((key) => {
          dispatch(
            setNotification({
              message: violations[key],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      if (data) {
        dispatch(setUser(data));
      }
      if (typeof successCallback === 'function') {
        successCallback();
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.UPDATE_PAYMENT));
    }
  };

export const setTransactions = (transactions: Transactions) => ({
  type: TYPES.SET_TRANSACTIONS,
  payload: {
    transactions,
  },
});
export const appendTransactions = (transactions: Transactions) => ({
  type: TYPES.APPEND_TRANSACTIONS,
  payload: {
    transactions,
  },
});
export const getTransactions =
  (offset: number = 0, limit: number = 20) =>
  async (dispatch: Dispatch) => {
    const clientType =
      offset === 0
        ? CLIENT_TYPE.USER_CLIENT.GET_TRANSACTIONS
        : CLIENT_TYPE.USER_CLIENT.GET_MORE_TRANSACTIONS;
    dispatch(setNetworkActivity(clientType));
    // $FlowFixMe
    const { notModified, data, error } = await userClient.getTransactions(
      offset,
      limit
    );

    if (error) {
      dispatch(setNetworkError(clientType, error));
    } else {
      if (!!data && !notModified) {
        if (offset === 0) {
          dispatch(setTransactions(data));
        } else {
          dispatch(appendTransactions(data));
        }
      }

      dispatch(setNetworkSuccess(clientType));
    }
  };
export const getTransactionPaymentLink =
  (transactionId: number, successCallback: (arg0: any) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.USER_CLIENT.GET_TRANSACTION_PAYMENT_LINK)
    );
    // $FlowFixMe
    const { notModified, data, error } =
      await userClient.getTransactionPaymentLink(transactionId);

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.USER_CLIENT.GET_TRANSACTION_PAYMENT_LINK,
          error
        )
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (!notModified) {
        successCallback(data);

        if (!data) {
          dispatch(
            setNotification({
              message: 'user.transaction.settled.success',
              type: APP.NOTIFICATION_TYPE.INFO,
            })
          );
        }
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.GET_TRANSACTION_PAYMENT_LINK)
      );
    }
  };
export const getMoreTransactions =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const existingTransactions = getState().userData.user.transactions;
    const existingCount = existingTransactions
      ? existingTransactions.transactions.length
      : 0;

    if (existingCount === 0) {
      dispatch(getTransactions());
    } else {
      const totalCount = existingTransactions
        ? existingTransactions.totalCount
        : 0;
      const offset =
        totalCount > 0 && totalCount > existingCount ? existingCount : 0;

      if (offset > 0) {
        dispatch(getTransactions(offset));
      }
    }
  };
export const setRentalHistory = (rentalHistory: RentalHistoryDataList) => ({
  type: TYPES.SET_RENTAL_HISTORY,
  payload: {
    rentalHistory,
  },
});
export const appendRentalHistory = (rentalHistory: RentalHistoryDataList) => ({
  type: TYPES.APPEND_RENTAL_HISTORY,
  payload: {
    rentalHistory,
  },
});
export const getRentalHistory =
  (afterId: string | null | undefined = null) =>
  async (dispatch: Dispatch) => {
    const clientType = afterId
      ? CLIENT_TYPE.USER_CLIENT.GET_MORE_RENTAL_HISTORY
      : CLIENT_TYPE.USER_CLIENT.GET_RENTAL_HISTORY;
    dispatch(setNetworkActivity(clientType));
    // $FlowFixMe
    const { notModified, data, error } = await userClient.getRentalHistory(
      afterId
    );

    if (error) {
      dispatch(setNetworkError(clientType, error));
    } else {
      if (!!data && !notModified) {
        if (afterId) {
          dispatch(appendRentalHistory(data));
        } else {
          dispatch(setRentalHistory(data));
        }
      }

      dispatch(setNetworkSuccess(clientType));
    }
  };
export const getMoreRentalHistory =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const existingRentalHistory =
      getState().userData.user.rentalHistory.rentals;
    const lastTripId =
      existingRentalHistory.length > 0
        ? existingRentalHistory[existingRentalHistory.length - 1].tripId
        : null;
    dispatch(getRentalHistory(lastTripId));
  };
export const terminateUser =
  (successCallback: () => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.TERMINATE_USER));
    const { error } = await userClient.terminateUser();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.TERMINATE_USER, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      dispatch(clearAuth());

      if (typeof successCallback === 'function') {
        successCallback();
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.TERMINATE_USER));
    }
  };
export const jumioDocumentUploaded =
  (scanReference: string, type: any, failCallback: () => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.USER_CLIENT.JUMIO_DOCUMENT_UPLOADED)
    );
    const { error } = await userClient.jumioDocumentUploaded(
      scanReference,
      type
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.JUMIO_DOCUMENT_UPLOADED, error)
      );

      if (typeof failCallback === 'function') {
        failCallback();
      }
    } else {
      dispatch(
        setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.JUMIO_DOCUMENT_UPLOADED)
      );
    }
  };
export const schufaCheck =
  (
    updateUserInputData: UpdateUserInputData,
    successCallbackFunction?: (arg0: any) => void,
    failureCallbackFunction?: () => void,
    isPayInCheck: boolean = false
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.SCHUFA_CHECK));
    const { notModified, data, error } = await userClient.schufaCheck(
      updateUserInputData
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.SCHUFA_CHECK, error));

      if (Object.keys(updateUserInputData)?.length > 0) {
        if (error.detail.status === 400) {
          Object.keys(error.detail.data.violations).forEach((violation) => {
            dispatch(
              setNotification({
                message: error.detail.data.violations[violation],
                type: APP.NOTIFICATION_TYPE.ERROR,
                localize: false,
              })
            );
          });
        } else {
          if (error.detail.status !== 403) {
            dispatch(
              setNotification({
                message: 'backend.error',
                type: APP.NOTIFICATION_TYPE.ERROR,
              })
            );
          }
        }
      }
      if (typeof failureCallbackFunction === 'function') {
        failureCallbackFunction();
      }
    } else {
      if (!!data && !notModified) {
        dispatch(setUser(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.SCHUFA_CHECK));

      if (
        !isPayInCheck &&
        !data?.supportedPaymentTypes?.includes('sepa') &&
        !data?.deposit?.toPayForSepaUnlock
      ) {
        dispatch(
          setNotification({
            title: 'schufa.notification.title',
            message: 'schufa.notification.message',
            actionText: 'ok',
            type: APP.NOTIFICATION_TYPE.CUSTOM,
            duration: 5000,
          })
        );
      }

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction(data);
      }
    }
  };
export const addressCheck =
  (
    userInputData: UpdateUserInputData,
    successCallback: () => void,
    failureCallback: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.ADDRESS_CHECK));
    const { notModified, data, error } = await userClient.addressCheck(
      userInputData
    );

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.ADDRESS_CHECK, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }

      if (typeof failureCallback === 'function') {
        failureCallback();
      }
    } else {
      if (!!data && !notModified) {
        dispatch(setUser(data));
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.ADDRESS_CHECK));

      if (typeof successCallback === 'function') {
        successCallback();
      }
    }
  };
export const sendVerificationCode =
  (isResend: boolean = true) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.USER_CLIENT.SEND_VERIFICATION_CODE)
    );
    const { error } = await userClient.sendVerificationCode();

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.SEND_VERIFICATION_CODE, error)
      );

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }

      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else {
      if (isResend) {
        dispatch(
          setNotification({
            message: 'phone.verification.resend.success',
            type: APP.NOTIFICATION_TYPE.INFO,
          })
        );
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.SEND_VERIFICATION_CODE)
      );
    }
  };
export const joinCircle =
  (circleCode: string, callbackFunction?: () => any) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.JOIN_CIRCLE));
    const { data, error } = await userClient.joinCircle(circleCode);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.JOIN_CIRCLE, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (data) {
        dispatch(setUser(data));

        if (typeof callbackFunction === 'function') {
          callbackFunction();
        }

        const selectedCircle = data?.circles?.find(
          (circle) => circle.circleCode === circleCode
        );
        dispatch(switchAccessToCircle(selectedCircle?.id!));
        dispatch(
          setNotification({
            title: 'circles.join.success.title',
            message: 'circles.join.success.msg',
            type: APP.NOTIFICATION_TYPE.CUSTOM,
            duration: 6000,
          })
        );
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.JOIN_CIRCLE));
    }
  };
export const removeCircle =
  (circleCode: string) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.REMOVE_CIRCLE));
    const { data, error } = await userClient.removeCircle(circleCode);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.REMOVE_CIRCLE, error));

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (data) {
        dispatch(setUser(data));

        if (data.circles && data.circles.length > 0) {
          dispatch(switchAccessToCircle(data.circles[0].id!));
        } else {
          dispatch(switchAccessToCircle(null));
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.REMOVE_CIRCLE));
    }
  };
export const checkCircle =
  (circleCode: string, callbackFunction?: () => any) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.AUTH_CLIENT.CHECK_CIRCLE));
    dispatch(setAuth('', ''));
    const { error } = await authClient.checkCircle({
      circleCode,
      email: '',
      password: '',
      firstName: '',
    });
    let success = false;

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.AUTH_CLIENT.CHECK_CIRCLE, error));

      if (error.detail.status === 400) {
        if (!Object.keys(error.detail.data.violations).includes('circleCode')) {
          success = true;
          dispatch(setCircleCode(circleCode));

          if (typeof callbackFunction === 'function') {
            callbackFunction();
          }
        }
      }
    }

    if (!success) {
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    }

    dispatch(clearAuth(false));
    dispatch(setNetworkSuccess(CLIENT_TYPE.AUTH_CLIENT.CHECK_CIRCLE));
  };
export const uploadDocument =
  (uri: any, type: 'ADDRESS_PROOF' | 'OTHER', successCallBack?: () => void) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.UPLOAD_DOCUMENT));
    // $FlowFixMe
    const { error } = isWeb()
      ? await userClient.webUploadDocument(uri, type)
      : await userClient.uploadDocument(uri, type);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.UPLOAD_DOCUMENT, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(
        setNotification({
          message: 'document.upload.success',
          type: APP.NOTIFICATION_TYPE.INFO,
        })
      );

      if (typeof successCallBack === 'function') {
        successCallBack();
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.UPLOAD_DOCUMENT));
    }
  };
export const setPaymentPublicKey = (
  paymentPublicKey: string | null | undefined
): SetPaymentPublicKey => ({
  type: TYPES.SET_PAYMENT_PUBLIC_KEY,
  payload: {
    paymentPublicKey,
  },
});
export const getPaymentPublicKey =
  (successCallback: (arg0: string) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PUBLIC_KEY)
    );
    const { data, error } = await userClient.getPaymentPublicKey();

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PUBLIC_KEY, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      if (data) {
        successCallback(data);
        dispatch(setPaymentPublicKey(data));
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PUBLIC_KEY)
      );
    }
  };
export const submitIdentityShopper =
  (
    deviceData: DeviceFingerprintInput,
    successCallback: () => void = () => {},
    challengeCallback: (arg0: ChallengeShopperData) => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    const networkActivity = CLIENT_TYPE.PAYMENT_CLIENT.SUBMIT_IDENTITY_SHOPPER;
    dispatch(setNetworkActivity(networkActivity));
    const { data, error } = await userClient.submitIdentityShopper(deviceData);

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (data) {
      dispatch(setUser(data));

      // @ts-ignore
      if (data?.paymentMethods?.length > 0) {
        // @ts-ignore
        const paymentData = data?.paymentMethods[0]?.paymentData;

        if (!paymentData || Object.keys(paymentData).length === 0) {
          dispatch(
            setNotification({
              message: 'payment.secured.success',
              type: APP.NOTIFICATION_TYPE.INFO,
              localize: true,
            })
          );
          successCallback();
        } else if (paymentData?.challengeShopperData) {
          challengeCallback(paymentData?.challengeShopperData);
        }
      }

      dispatch(setNetworkSuccess(networkActivity));
    }
  };
export const submitChallenge =
  (
    challengeData: ChallengeSolutionInput,
    successCallback: () => void = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    const networkActivity = CLIENT_TYPE.PAYMENT_CLIENT.SUBMIT_CHALLENGE;
    dispatch(setNetworkActivity(networkActivity));
    const { data, error } = await userClient.submitChallenge(challengeData);

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (data) {
      dispatch(setUser(data));
      // @ts-ignore
      if (data?.paymentMethods.length > 0) {
        // @ts-ignore
        const paymentData = data?.paymentMethods[0]?.paymentData;

        if (!paymentData || Object.keys(paymentData).length === 0) {
          dispatch(
            setNotification({
              message: 'payment.secured.success',
              type: APP.NOTIFICATION_TYPE.INFO,
              localize: true,
            })
          );
          successCallback();
        }
      }

      dispatch(setNetworkSuccess(networkActivity));
    }
  };
export const isPaymentVerified =
  (successCallback: (arg0: UserDataOutput) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    const networkActivity = CLIENT_TYPE.PAYMENT_CLIENT.IS_PAYMENT_VERIFIED;
    dispatch(setNetworkActivity(networkActivity));
    const { data, error } = await userClient.isPaymentVerified();

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (data) {
      dispatch(setUser(data));
      successCallback(data);
      dispatch(setNetworkSuccess(networkActivity));
    }
  };
export const initJumioForMobile =
  (
    documentType: JumioWebDocumentType = 'DRIVING_LICENSE',
    successCallback: (arg0: any) => void = () => {},
    successUrl?: string,
    errorUrl?: string
  ) =>
  async (dispatch: Dispatch) => {
    const networkActivity = CLIENT_TYPE.USER_CLIENT.INIT_JUMIO_FOR_MOBILE;
    dispatch(setNetworkActivity(networkActivity));
    const jumioWebInput: any = {
      documentType,
      successUrl,
      errorUrl,
    };
    const { data, error } = await userClient.initJumioForMobile(jumioWebInput);

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          dispatch(
            setNotification({
              message: error.detail.data.violations[violation],
              type: APP.NOTIFICATION_TYPE.ERROR,
              localize: false,
            })
          );
        });
      }
    } else if (data) {
      dispatch(setNetworkSuccess(networkActivity));
      successCallback(data);
    }
  };
export const initJumioForWeb =
  (
    localize: LocalizePropType,
    history: any,
    documentType: JumioWebDocumentType = 'DRIVING_LICENSE',
    transactionReference: any = null,
    userId: string,
    isDashboardScreen: boolean,
    isKYCApp: boolean,
    isOptimizedKYC: boolean,
    successCallback: (arg0: any) => void = () => {},
    successUrl: string | null = null,
    errorUrl: string | null = null
  ) =>
  async (dispatch: Dispatch) => {
    const networkActivity = CLIENT_TYPE.USER_CLIENT.INIT_JUMIO_FOR_WEB;
    dispatch(setNetworkActivity(networkActivity));
    const jumioWebInput: any = {
      documentType,
      userId,
    };
    if (successUrl) jumioWebInput.successUrl = successUrl;
    if (errorUrl) jumioWebInput.errorUrl = errorUrl;
    if (transactionReference)
      jumioWebInput.transactionReference = transactionReference;
    const { data, error } = await userClient.initJumioForWeb(jumioWebInput);

    if (error) {
      dispatch(setNetworkError(networkActivity, error));
      if (error.detail.status === 400) {
        Object.keys(error.detail.data.violations).forEach((violation) => {
          if (violation === 'api_error.user.tariff_missing') {
            showTariffRequiredDialog(
              localize,
              () => history.push('/dashboard/tariff'),
              violation
            );
          } else {
            dispatch(
              setNotification({
                message: error.detail.data.violations[violation],
                type: APP.NOTIFICATION_TYPE.ERROR,
                localize: false,
              })
            );
          }
        });
      } else {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else if (data) {
      dispatch(
        setJumioRedirectData({
          ...data,
          isDashboardScreen,
          documentType,
          isKYCApp,
          isOptimizedKYC,
        })
      );
      dispatch(setNetworkSuccess(networkActivity));
      successCallback(data);
    }
  };
export const verifyJumioSuccess =
  (scanRef: any = null, successCallback: () => void = () => {}) =>
  async (dispatch: Dispatch) => {
    if (scanRef) {
      const networkActivity = CLIENT_TYPE.USER_CLIENT.VERIFY_JUMIO_SUCCESS;
      dispatch(setNetworkActivity(networkActivity));
      const { error } = await userClient.verifyJumioSuccess(scanRef);

      if (error) {
        dispatch(setNetworkError(networkActivity, error));
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      } else {
        successCallback();
        dispatch(setNetworkSuccess(networkActivity));
      }
    }
  };

export const generateDepositPaymentLink =
  (
    amount: number,
    returnUrl = window?.location?.origin,
    successCallBack?: (paymentLink: string) => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(
      setNetworkActivity(CLIENT_TYPE.USER_CLIENT.GENERATE_DEPOSIT_PAYMENT_LINK)
    );
    // $FlowFixMe
    const { data, error } = await userClient.generateDepositPaymentLink(
      amount,
      returnUrl
    );

    if (error) {
      dispatch(
        setNetworkError(
          CLIENT_TYPE.USER_CLIENT.GENERATE_DEPOSIT_PAYMENT_LINK,
          error
        )
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (data) {
      if (typeof successCallBack === 'function') {
        successCallBack(data?.url);
      }

      dispatch(
        setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.GENERATE_DEPOSIT_PAYMENT_LINK)
      );
    }
  };

export const refundDeposit =
  (successCallBack?: () => void) => async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.REFUND_DEPOSIT));
    // $FlowFixMe
    const { data, error } = await userClient.refundDeposit();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.REFUND_DEPOSIT, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (data) {
      dispatch(setUser(data));
      if (typeof successCallBack === 'function') {
        successCallBack();
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.REFUND_DEPOSIT));
    }
  };

export const loadUserTariffs =
  (successCallBack?: (arg: any) => void) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.LOAD_TARIFFS));
    // $FlowFixMe
    const { data, error } = await userClient.loadTariffs();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.USER_CLIENT.LOAD_TARIFFS, error));
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (data) {
      const { user } = getState().userData.user;
      const extraData = user?.extraData ? JSON.parse(user.extraData) : {};
      if (isFRM()) {
        const filteredTariffs = data?.tariffs?.filter((tariff) =>
          extraData?.partner
            ? tariff?.name?.includes('Partner')
            : !tariff?.name?.includes('Partner')
        );
        dispatch(setTariffs(filteredTariffs));
      } else {
        dispatch(setTariffs(data?.tariffs));
      }

      if (typeof successCallBack === 'function') {
        successCallBack(data);
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.LOAD_TARIFFS));
    }
  };

export const generateMagicLink =
  (sendEmail: boolean, successCallback: (data: any) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.USER_CLIENT.GENERATE_MAGIC_LINK));
    const { data, error } = await userClient.generateMagicLink(sendEmail);

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.USER_CLIENT.GENERATE_MAGIC_LINK, error)
      );

      if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (data) {
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.USER_CLIENT.GENERATE_MAGIC_LINK));
    }
  };
