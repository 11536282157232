import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBrandSettings,
  getCategories,
  getAreas,
  getTexts,
  selectUser,
  getRentalData,
  getServices,
  getBookings,
  getCarEquipments,
  getFeatures,
  searchCars,
  searchCarsCompact,
  selectRegionChangeNo,
  setRegionChangeNo,
  REGION_CHANGE_THRESHOLD,
  isKYCApp,
  selectIsVerifyingGDPREmail,
  selectIsEditingPhone,
  getUser,
  verifyJumioSuccess,
  setIsEmbedded,
  setFrom,
  setBackBy,
} from 'getaway-data-layer';
import { setCustomBasicAuth } from 'getaway-data-layer/src/redux/store/userData/user/actions';
import { setShowExternalBrowserScreen } from 'getaway-data-layer/src/redux/store/ui/firstSteps/Actions';
import LoadingOverlay from 'react-loading-overlay';

type props = {
  children: React.ReactElement;
};

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

const Data = ({ children }: props) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isEmbedded = query?.get('mode') === 'embedded';
  const utm = query?.get('utm_source')?.toLowerCase();
  const magicLink = query?.get('l');
  const base6Magic = magicLink ? btoa(magicLink) : null;
  const user: any = useSelector(selectUser);
  const regionChangeNo: number = useSelector(selectRegionChangeNo);
  const isVerifyingGDPREmail = useSelector(selectIsVerifyingGDPREmail);
  const isEditingPhone = useSelector(selectIsEditingPhone);

  const [isAuthenticating, setIsAuthenticating] = useState(!!magicLink);

  useEffect(() => {
    dispatch(setIsEmbedded(isEmbedded));
  }, []);

  useEffect(() => {
    const fromParam = query.get('from');
    const backByParam = query.get('backBy');

    if (fromParam) {
      dispatch(setFrom(new Date(fromParam).toUTCString()));
    }
    if (backByParam) {
      dispatch(setBackBy(new Date(backByParam).toUTCString()));
    }
  }, []);

  useEffect(() => {
    if (magicLink) {
      dispatch(
        setCustomBasicAuth(`Bearer ${base6Magic}`, () => {
          dispatch(
            getUser(
              () => setIsAuthenticating(false),
              () => setIsAuthenticating(false)
            )
          );
        })
      );
    } else {
      setIsAuthenticating(false);
    }
  }, [base6Magic, dispatch]);

  useEffect(() => {
    // Native apps users
    if (utm?.includes('app')) {
      dispatch(setShowExternalBrowserScreen(true));
    }

    if (!isKYCApp()) {
      setTimeout(() => {
        dispatch(
          searchCars(null, false, true, () => {
            dispatch(searchCars(500000));
          })
        );
      }, 200);
    }

    // @ts-ignore
    if (!user && window?.Intercom) {
      // @ts-ignore
      window?.Intercom('shutdown');
      // @ts-ignore
      window?.Intercom('boot');
    }
    if (!isVerifyingGDPREmail && !isEditingPhone) {
      if (user) {
        // @ts-ignore
        if (window?.Intercom) {
          // @ts-ignore
          window.Intercom('boot', {
            email: user.email,
            created_at: 1234567890,
            name: `${user.firstName} ${user.lastName}`,
            user_id: user.id,
          });
        }
        if (!isKYCApp()) {
          setTimeout(() => {
            new Promise<void>((resolve) => {
              dispatch(getRentalData());
              dispatch(
                getServices(() => {
                  resolve();
                })
              );
            })
              .then(() => {
                dispatch(getBookings(null, false));
              })
              .then(() => {
                dispatch(getCarEquipments());
              })
              .then(() => {
                dispatch(getFeatures());
              });
          }, 300);
        }
      }
      setTimeout(() => {
        new Promise<void>((resolve) => {
          dispatch(getBrandSettings());
          dispatch(getTexts(() => resolve()));
        })
          .then(() => {
            if (!isKYCApp()) {
              dispatch(getAreas());
            }
          })
          .then(() => {
            if (!isKYCApp()) {
              dispatch(getCategories());
            }
          });
      }, 500);
    }
  }, [user?.version]);

  // verifyJumioSuccess when coming back from jumio scanning stage
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const transactionReference = urlParams.get('transactionReference');
    if (transactionReference) {
      dispatch(verifyJumioSuccess(transactionReference));
    }

    const workflowExecutionId = urlParams.get('workflowExecutionId');
    if (workflowExecutionId) {
      dispatch(verifyJumioSuccess(workflowExecutionId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !isEmbedded &&
      regionChangeNo > REGION_CHANGE_THRESHOLD &&
      !isKYCApp()
    ) {
      dispatch(setRegionChangeNo(0));
      dispatch(searchCarsCompact());
    }
  }, [regionChangeNo]);

  if (isAuthenticating) {
    return <LoadingOverlay active spinner className="loading-overlay" />;
  }

  return children;
};

export default Data;
