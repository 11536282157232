import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Card from '../Card/Card';
import './TariffItem.less';
import { Checkbox } from 'antd';
import { LocalizePropType, UpsellingData } from 'getaway-data-layer';

type Props = {
  title: string;
  price: string;
  description: string;
  upsellings: UpsellingData[];
  deductionTitle?: string;
  deductionPrice?: string;
  selected: boolean;
  onClick: () => void;
  localize: LocalizePropType;
  upsellingSelected: string;
  setUpsellingSelected: (code: string) => void;
  showExtraOptionsLink?: boolean;
  onClickExtraOptions?: () => void;
};

const TariffItem = ({
  localize,
  title,
  price,
  description,
  upsellings,
  selected,
  onClick,
  upsellingSelected,
  setUpsellingSelected,
  showExtraOptionsLink,
  onClickExtraOptions,
}: Props) => {
  return (
    <Card hideShadow={selected}>
      <div
        className={selected ? 'tariff-container-selected' : 'tariff-container'}
        onClick={onClick}
      >
        <div className="tariff-radio-wrapper">
          <input
            type="radio"
            id="radioButton"
            name="radioGroup"
            className="tariff-radio"
            checked={selected}
          />
        </div>
        <div className="tariff-header">
          <strong className="tariff-title">{title}</strong>
          <div className="tariff-price">
            <Markdown rehypePlugins={[rehypeRaw]}>{price}</Markdown>
          </div>
        </div>
        <Markdown rehypePlugins={[rehypeRaw]} className="tariff-desc">
          {description}
        </Markdown>
        {showExtraOptionsLink && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onClickExtraOptions();
            }}
            className="tariff-link"
          >
            {localize('tariff.extra.options.link')}
          </div>
        )}
        {selected &&
          upsellings?.map(({ code }) => (
            <>
              <div className="tariff-separator" />
              <div
                className="tariff-upselling"
                onClick={(e) => {
                  e.stopPropagation();
                  setUpsellingSelected(upsellingSelected ? null : code);
                }}
              >
                <div style={{ display: 'flex', alignContent: 'flex-start' }}>
                  <img
                    className="tariff-upselling-icon"
                    src="/images/starShield.png"
                    alt="deduction"
                  />
                  <div>
                    <div className="tariff-upselling-title noselect">
                      <Markdown rehypePlugins={[rehypeRaw]}>
                        {localize(`tariff.upselling.${code}.title`)}
                      </Markdown>
                    </div>
                    <div className="tariff-upselling-price noselect">
                      <Markdown rehypePlugins={[rehypeRaw]}>
                        {localize(`tariff.upselling.${code}.price`)}
                      </Markdown>
                    </div>
                  </div>
                </div>
                <Checkbox
                  checked={upsellingSelected === code}
                  style={{ marginRight: 8 }}
                />
              </div>
            </>
          ))}
      </div>
    </Card>
  );
};

TariffItem.defaultProps = {};

export default TariffItem;
