/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBackby,
  selectFrom,
  selectSelectedCar,
  withTextLocalizer,
  dateAndTimeStringFromDate,
  bookCar,
  locationFromString,
  LocalizePropType,
  selectUser,
  selectDevicePaired,
  selectFeatures,
  setNotification,
  sendVerificationCode,
  isPaymentVerified,
  submitIdentityShopper,
  submitChallenge,
  setRecoverPaymentAuth,
  selectCircleId,
  estimateCost,
  setOpenedModal,
  switchAccessToCircle,
  getBrandName,
  selectPricing,
  getCarPriceDetails,
  setPreSignupBooking,
  selectPreSignupBooking,
  parseAddress,
  updatePayment,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import BookingConfirmationView, {
  getPaymentTitle,
} from './BookingConfirmationView';
import { openRouteDialog } from '../../../../utils/dialogsHelpers';
import { useCustomerSupport } from '../../../../hooks/useCustomerSupport';
import { handleRentalViolations } from 'getaway-data-layer/src/helpers/rentalsViolationsHelpers';
import { navigateTo } from '../../../../utils';
import config from '../../../../env.config';
import { addHours, subMinutes } from 'date-fns';

type Props = {
  localize: LocalizePropType;
};

const BookingConfirmationContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const circleId = useSelector(selectCircleId) as any;
  const user = useSelector(selectUser);
  const devicePaired = useSelector(selectDevicePaired);
  const features = useSelector(selectFeatures);
  const preSignupBooking = useSelector(selectPreSignupBooking);
  const pricing = useSelector(selectPricing) as any;
  const { helpAction } = useCustomerSupport(localize);
  const selectedCircle = user?.circles.find((item) => item?.id === circleId);
  const selectedCar =
    preSignupBooking?.selectedCar || (useSelector(selectSelectedCar) as any);
  const [selectedInsurance, setSelectedInsurance] = useState(
    preSignupBooking?.selectedInsurance || ''
  );
  const [sortedInsurances, setSortedInsurances] = useState([]) as any[];
  const [estimatedCost, setEstimatedCost] = React.useState(null);
  const [isDriverInjurySelected, setIsDriverInjurySelected] = useState(
    preSignupBooking?.isDriverInjurySelected || false
  );
  const address = parseAddress(selectedCar?.releaseAddress);
  const from = preSignupBooking?.from || (useSelector(selectFrom) as any);
  const backBy = preSignupBooking?.backBy || (useSelector(selectBackby) as any);
  const fromDate = new Date(from);
  const formattedFrom = fromDate
    ? dateAndTimeStringFromDate(fromDate, localize)
    : '';
  const backByDate = new Date(backBy);
  const formattedBackBy = backByDate
    ? dateAndTimeStringFromDate(backByDate, localize)
    : '';
  const cancellationDueDate = fromDate
    ? dateAndTimeStringFromDate(subMinutes(fromDate, 1), localize)
    : '';
  const alternativeCancelDueDate = fromDate
    ? dateAndTimeStringFromDate(addHours(fromDate, 2), localize)
    : '';
  const defaultPaymentMethod = user?.paymentMethods?.find(
    (payment) => payment?.defaultOne
  );

  const onClose = () => {
    history.goBack();
  };

  const setInitialInsurance = () => {
    if (selectedCar) {
      const { defaultInsuranceData, insurancesData } = selectedCar;
      const sortedInsurancesArray = !user
        ? [...insurancesData].filter((item) => !item?.youngDriver)
        : [...insurancesData];
      sortedInsurancesArray.sort(
        (a: any, b: any) => b.deductibleAmount - a.deductibleAmount
      );
      const initialSelectedInsurance =
        defaultInsuranceData?.id &&
        sortedInsurancesArray
          .map((insurance: any) => insurance.id)
          .includes(defaultInsuranceData.id)
          ? defaultInsuranceData.id
          : sortedInsurancesArray && sortedInsurancesArray.length > 0
          ? sortedInsurancesArray[0].id
          : ' ';
      setSortedInsurances(sortedInsurancesArray);
      setSelectedInsurance(
        preSignupBooking?.selectedInsurance || initialSelectedInsurance
      );
    }
  };

  const onInsuranceSelected = (insurance: string) => {
    setSelectedInsurance(insurance);
  };

  const onSubmit = () => {
    if (!user) {
      dispatch(
        setPreSignupBooking({
          selectedInsurance,
          isDriverInjurySelected,
          selectedCar,
          from,
          backBy,
        })
      );
      navigateTo(history, `/signup`);
      return;
    }
    const notificationCallback = () => {};
    const successCallback = (booking) => {
      dispatch(setPreSignupBooking(null));
      navigateTo(history, `/booking/${booking.id}`);
    };
    const orderedOptions = isDriverInjurySelected ? 'F3023' : [];
    if (selectedCar?.id) {
      dispatch(
        bookCar(
          localize,
          new Date(from),
          new Date(backBy),
          locationFromString(selectedCar?.position),
          selectedInsurance,
          orderedOptions,
          undefined,
          selectedCar?.id,
          true,
          null,
          notificationCallback,
          successCallback,
          (violations) =>
            handleRentalViolations(
              violations,
              user,
              localize,
              setNotification,
              devicePaired,
              null,
              features,
              sendVerificationCode,
              helpAction,
              isPaymentVerified,
              submitIdentityShopper,
              submitChallenge,
              setRecoverPaymentAuth,
              dispatch,
              history
            )
        )
      );
    }
  };

  const onAddressClicked = () => {
    openRouteDialog(
      localize,
      locationFromString(selectedCar?.position),
      address
    );
  };

  const onPressPriceInfo = () => {
    dispatch(setOpenedModal('CarProfileModal'));
  };

  const onChangeCircle = (newCircleId) => {
    dispatch(
      estimateCost(
        localize,
        new Date(from),
        new Date(backBy),
        selectedCar.id,
        newCircleId,
        (res) => {
          setEstimatedCost(res);
        }
      )
    );
    dispatch(switchAccessToCircle(newCircleId));
  };

  const onClickCircles = async () => {
    const options = user?.circles.map((circle: any) => ({
      title:
        circle.name ||
        localize(`brand.${getBrandName(config.brand.xBrandId!)}.name`),
      action: () => onChangeCircle(circle.id),
    }));
    const { showAlertWithOptions } = await import(
      'getaway-data-layer/src/helpers/showAlert'
    );
    showAlertWithOptions('', '', options);
  };

  const onPressExtraOption = () => {
    setIsDriverInjurySelected(!isDriverInjurySelected);
  };

  const clearPreSignupBooking = () => {
    history.goBack();
    setTimeout(() => {
      dispatch(setPreSignupBooking(null));
    }, 200);
  };

  const onChangePaymentMethod = async () => {
    const options = user?.paymentMethods
      ?.filter((payment) => payment.status === 'verified')
      ?.map((payment: any) => ({
        title: `${getPaymentTitle(payment, localize)} | ${localize(
          payment?.billingInfo?.billingProfile
        )}`,
        action: () => {
          dispatch(
            updatePayment(
              payment.id,
              {
                defaultOne: true,
              },
              () => {
                dispatch(
                  estimateCost(
                    localize,
                    new Date(from),
                    new Date(backBy),
                    selectedCar?.id,
                    selectedCircle?.id,
                    (res) => {
                      setEstimatedCost(res);
                    }
                  )
                );
              }
            )
          );
        },
      }));
    const { showAlertWithOptions } = await import(
      'getaway-data-layer/src/helpers/showAlert'
    );
    showAlertWithOptions(localize('booking.payment.change'), '', options);
  };

  useEffect(() => {
    setInitialInsurance();
    dispatch(
      estimateCost(
        localize,
        new Date(from),
        new Date(backBy),
        selectedCar?.id,
        circleId,
        (res) => {
          setEstimatedCost(res);
        }
      )
    );

    dispatch(
      getCarPriceDetails(selectedCar?.driverPrice?.pricingId, selectedCar?.id)
    );
  }, []);

  return (
    <BookingConfirmationView
      user={user}
      circles={user?.circles}
      selectedCircle={selectedCircle}
      estimatedCost={estimatedCost}
      localize={localize}
      onClose={onClose}
      carPlate={selectedCar?.plate}
      address={address}
      preSignupBooking={preSignupBooking}
      clearPreSignupBooking={clearPreSignupBooking}
      cancellationDueDate={cancellationDueDate}
      alternativeCancelDueDate={alternativeCancelDueDate}
      cancellationFee={
        pricing[selectedCar?.id!!]?.feeItems?.find(
          (item: { code: string }) => item?.code === 'F3025'
        )?.price
      }
      mbaCancellationFee={
        pricing[selectedCar?.id!!]?.feeItems?.find(
          (item: { code: string }) => item?.code === 'F3003'
        )?.price
      }
      insurancesData={sortedInsurances}
      selectedInsurance={selectedInsurance}
      onInsuranceSelected={onInsuranceSelected}
      from={formattedFrom}
      until={formattedBackBy}
      onSubmit={onSubmit}
      onAddressClicked={onAddressClicked}
      onPressPriceInfo={onPressPriceInfo}
      onClickCircles={onClickCircles}
      extras={pricing[selectedCar?.id!!]?.feeItems?.filter(
        (item: { type: string }) => item?.type === 'extra'
      )}
      isDriverInjurySelected={isDriverInjurySelected}
      onPressExtraOption={onPressExtraOption}
      defaultPaymentMethod={defaultPaymentMethod}
      onChangePaymentMethod={onChangePaymentMethod}
    />
  );
};

export default withTextLocalizer(BookingConfirmationContainer);
