import { isWeb } from './platformHelpers';
import { LocalizePropType } from '../enhancers';
import { injectedRate } from '../redux/ConfigureGlobals';

export const showAppReviewDialog = async (
  localize: LocalizePropType,
  bundleId: string,
  appleAppId: string,
  successCallback: () => void = () => {}
): Promise<any> => {
  if (!isWeb()) {
    const { showAlert } = await import('./showAlert');
    showAlert(
      localize('review.dialog.title'),
      localize('review.dialog.message'),
      localize('review.dialog.yes'),
      () => {
        injectedRate.rate(
          {
            AppleAppID: appleAppId,
            GooglePackageName: bundleId,
            preferInApp: true,
            openAppStoreIfInAppFails: true,
            inAppDelay: 5.0,
          },
          (success: boolean) => {
            if (success) {
              successCallback();
            }
          }
        );
      },
      localize('review.dialog.later')
    );
  }
};
