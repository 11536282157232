import { CLIENT_TYPE, Dispatch, GetState } from '../../../../@types';
import {
  setNetworkActivity,
  setNetworkError,
  setNetworkSuccess,
} from '../../networkStatus';
import { brandClient } from '../../../clients/brandClient';
import { setPhoneCode } from '../../ui/firstSteps';
import { setPhoneCodes } from '../../ui/firstSteps/Actions';
import { getPhoneCodeByCountry } from '../../../../helpers/phoneHelpers';
import { setBrandSettings } from './actions';

export const getBrandSettings =
  (callbackFunction?: (arg0: string) => void) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.BRAND_CLIENT.GET_SETTINGS));
    const { phoneCodes } = getState().ui?.firstSteps;

    const { data, error } = await brandClient.getSettings();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.BRAND_CLIENT.GET_SETTINGS, error));
    } else {
      if (data) {
        dispatch(setBrandSettings(data));

        if (data?.defaultCountryCode) {
          // @ts-ignore
          dispatch(
            setPhoneCode({
              country: data?.defaultCountryCode.toUpperCase(),
              code: getPhoneCodeByCountry(
                data?.defaultCountryCode.toUpperCase()
              ),
            })
          );
        }

        if (data?.allowedPhoneNumberCountries) {
          dispatch(
            setPhoneCodes(
              data.allowedPhoneNumberCountries.map((country) => ({
                country: country.toUpperCase(),
                code: getPhoneCodeByCountry(country.toUpperCase()),
              }))
            )
          );
        }
        if (typeof callbackFunction === 'function') {
          callbackFunction(data?.intercomHandlingMode!);
        }
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.BRAND_CLIENT.GET_SETTINGS));
    }
  };
