import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dateAndTimeStringFromDate,
  isKYCApp,
  LocalizePropType,
  schufaCheck,
  selectUser,
  updateUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import ConsentsView from './ConsentsView';
import {
  dateFromObjectId,
  isIncompleteProfile,
  navigateTo,
} from '../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const ConsentsContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const schufaAgreedAt = user?.actions?.find(
    (action) => action?.name === 'creditWorthinessAgreed'
  )?.created;
  const newsLetterConsentAt = user?.actions?.find(
    (action) => action?.name === 'marketingConsentGiven'
  )?.created;

  const inCompleteProfile = isIncompleteProfile(user);
  let onboardingOnlyProduct = isKYCApp();
  const readOnlyMode = !inCompleteProfile && onboardingOnlyProduct;

  const onClose = () => {
    navigateTo(history, '/settings');
  };

  const onUpdateSchufa = () => {
    dispatch(schufaCheck({}));
  };

  const onUpdateNewsLetter = () => {
    dispatch(
      updateUser({ marketingConsentGiven: !user?.marketingConsentGiven })
    );
  };

  return (
    <ConsentsView
      localize={localize}
      onClose={onClose}
      consentDate={dateAndTimeStringFromDate(
        dateFromObjectId(user.id) as Date,
        localize
      )}
      schufaAgreed={!!schufaAgreedAt}
      schufaAgreedAt={
        schufaAgreedAt
          ? dateAndTimeStringFromDate(new Date(schufaAgreedAt), localize)
          : ''
      }
      marketingConsentGiven={user?.marketingConsentGiven}
      newsLetterConsentAt={
        user?.marketingConsentGiven
          ? dateAndTimeStringFromDate(new Date(newsLetterConsentAt), localize)
          : ''
      }
      onUpdateSchufa={onUpdateSchufa}
      onUpdateNewsLetter={onUpdateNewsLetter}
      readOnlyMode={readOnlyMode}
    />
  );
};

export default withTextLocalizer(ConsentsContainer);
