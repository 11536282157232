import React, { useEffect, useState } from 'react';
import {
  withTextLocalizer,
  getUser,
  LocalizePropType,
  isServicesTabEnabled,
  isKYCApp,
  selectUser,
} from 'getaway-data-layer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProfileView from './ProfileView';
import {
  GA_TITLES,
  isIncompleteProfile,
  isMobileBrowser,
  navigateTo,
  reportGAEvent,
} from '../../../../utils';
import { PROFILE_TABS } from '../../../../enums/Profile';

type Props = {
  localize: LocalizePropType;
};

const ProfileContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);

  const inCompleteProfile = isIncompleteProfile(user);
  const onboardingOnlyProduct = isKYCApp();
  const readOnlyMode = !inCompleteProfile && onboardingOnlyProduct;
  const isServicesLink = history?.location?.pathname?.includes('/services');
  const [activeTab, setActiveTab] = useState(
    readOnlyMode || isServicesLink
      ? PROFILE_TABS.SERVICES
      : PROFILE_TABS.ACCOUNT
  );

  const onClose = () => {
    navigateTo(history, isMobileBrowser() ? '/map' : '/');
  };

  useEffect(() => {
    dispatch(getUser());
    reportGAEvent(GA_TITLES.DASHBOARD.PROFILE);
  }, []);

  return (
    <ProfileView
      localize={localize}
      onClose={onClose}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      enableServicesTab={isServicesTabEnabled()}
    />
  );
};

export default withTextLocalizer(ProfileContainer);
