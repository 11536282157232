import React from 'react';
import './ProfileView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { LocalizePropType, isKYCApp } from 'getaway-data-layer';
import ToggleTab from '../../../components/ToggleTab/ToggleTab';
import AccountTabContainer from './AccountTab/AccountTabContainer';
import ServicesTabContainer from './ServicesTab/ServicesTabContainer';
import { PROFILE_TABS } from '../../../../enums/Profile';

type Props = {
  localize: LocalizePropType;
  onClose: () => void;
  activeTab: string;
  setActiveTab: (arg: string) => void;
  enableServicesTab: boolean;
};

const ProfileView = ({
  localize,
  onClose,
  activeTab,
  setActiveTab,
  enableServicesTab,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('dashboard.profile')}
        description=""
        showHelp
        onClose={isKYCApp() ? undefined : onClose}
      />
      <div
        className="screen-body"
        style={enableServicesTab ? { paddingTop: 24 } : {}}
      >
        {enableServicesTab && (
          <ToggleTab
            localize={localize}
            optionOne={localize(`profile.tab.services`)}
            optionTwo={localize(`profile.tab.account`)}
            isOptionOneSelected={activeTab !== PROFILE_TABS.ACCOUNT}
            onToggleValueChange={() => {
              if (activeTab === PROFILE_TABS.ACCOUNT) {
                setActiveTab(PROFILE_TABS.SERVICES);
              } else {
                setActiveTab(PROFILE_TABS.ACCOUNT);
              }
            }}
            hideOR
          />
        )}
        {activeTab === PROFILE_TABS.ACCOUNT && <AccountTabContainer />}
        {activeTab === PROFILE_TABS.SERVICES && <ServicesTabContainer />}
      </div>
    </div>
  );
};

export default ProfileView;
