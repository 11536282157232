import { DriverCarData, locationFromString } from 'getaway-data-layer';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { getBrandColor } from 'getaway-data-layer/src/helpers/brandHelpers';
// @ts-ignore
import inside from 'point-in-polygon';

type TravelMode = 'driving' | 'walking' | 'bicycling' | 'transit';

export type Location = {
  latitude: number;
  longitude: number;
};

export const openRouteInGoogleMaps = (
  destination: Location,
  travelMode: TravelMode = 'walking'
) => {
  if (destination?.latitude && destination?.longitude) {
    const url = `https://www.google.com/maps/dir/?api=1&travelmode=${travelMode}&dir_action=navigate&destination=${destination.latitude},${destination.longitude}`;
    window.open(url);
  }
};

export const getPositionErrorMessage = (code: any) => {
  switch (code) {
    case 1:
      return 'Location permission denied.';
    case 2:
      return 'Location position unavailable.';
    case 3:
      return 'Location timeout reached.';
    default:
      return null;
  }
};

// @ts-ignore
export const trackLocation = ({ onSuccess, onError = () => {} }) => {
  if (!('geolocation' in navigator)) {
    // @ts-ignore
    return onError(new Error('Geolocation is not supported by your browser.'));
  }

  // Use watchPosition instead.
  return navigator.geolocation.watchPosition(onSuccess, onError);
};

// @ts-ignore
export const getCurrentPosition = ({ onSuccess, onError = () => {} }) => {
  if (!('geolocation' in navigator)) {
    // @ts-ignore
    return onError(new Error('Geolocation is not supported by your browser.'));
  }
  return navigator.geolocation.getCurrentPosition(onSuccess, onError);
};

export const isLocationPermissionGranted = async () => {
  const result = await navigator?.permissions?.query({ name: 'geolocation' });
  return result?.state === 'granted';
};

export const isCameraPermissionGranted = async () => {
  try {
    // @ts-ignore
    const result = await navigator.permissions.query({ name: 'camera' });
    if (result.state === 'granted') {
      return true;
    } else if (result.state === 'prompt' || result.state === 'denied') {
      try {
        const stream = await navigator?.mediaDevices?.getUserMedia({
          video: true,
        });
        // If we get a stream, it means the user granted access
        // Close the stream to avoid keeping it active
        stream?.getTracks()?.forEach((track) => track?.stop());
        return true; // Camera access was granted after prompting the user
      } catch (error) {
        // User denied the access or an error occurred
        return false;
      }
    } else {
      // The permissions API is not giving a clear answer, assume no access
      return false;
    }
  } catch (error) {
    // Permissions API or getUserMedia() failed, possibly due to lack of browser support or disabled device settings
    console.error('Error checking camera permission:', error);
    return false;
  }
};

export const svgUserMarker = {
  path: 'M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z',
  fillColor: getBrandColor(),
  fillOpacity: 1,
  strokeWeight: 4,
  strokeColor: 'white',
  rotation: 0,
  scale: 1,
};

export const getAreaCoordinates = (area: any) =>
  area.geoJSONData.features[0].geometry.coordinates.map((coordinate: any) =>
    locationFromString(coordinate.split(' ').reverse().join(','))
  );

export const getPloyCoordinates = (geoJSON: any) => {
  const cords = [] as any;
  const { features } = geoJSON;
  if (features && features.length) {
    features.forEach((feature: any) => {
      const { geometry } = feature;
      const { coordinates } = geometry;
      coordinates.forEach((coordinate: any) => {
        const coordinatePoint = locationFromString(coordinate, ' ', false);
        cords.push({
          lat: coordinatePoint.latitude,
          lng: coordinatePoint.longitude,
        });
      });
    });
  }
  return cords;
};

export const isInsidePolygon = (
  target: Location,
  targetPolygon: Array<Location>
): boolean => {
  if (target && targetPolygon) {
    const targetCoordinate = [target.latitude, target.longitude];
    const polygonCoordinates = targetPolygon.map((location) => [
      location.latitude,
      location.longitude,
    ]);
    return inside(targetCoordinate, polygonCoordinates);
  }
  return true;
};

export const isInsideArea = (target: Location, area: any): boolean => {
  if (target && area) {
    const areaLocations = getAreaCoordinates(area);
    return isInsidePolygon(target, areaLocations);
  }
  return true;
};

export const calculateAndDisplayRoute = (
  directionsService: any,
  directionsRenderer: any,
  origin: any,
  destination: any,
  travelMode = 'WALKING'
) => {
  directionsService
    .route({
      origin,
      destination,
      travelMode,
    })
    .then((response: any) => {
      directionsRenderer.setDirections(response);
    })
    // eslint-disable-next-line no-console
    .catch((e: any) => console.log(e));
};

export const pointFromLocation = (location: Location) =>
  point([location.longitude, location.latitude]);

export const distanceBetweenLocationsInKm = (
  location1: any,
  location2: any
): any => {
  if (location1 && location2) {
    const point1 = pointFromLocation(location1);
    const point2 = pointFromLocation(location2);
    const units = 'kilometers';
    // @ts-ignore
    return distance(point1, point2, units);
  }
  return 0;
};

export const sortCarsRelativeToPosition = (
  cars: Array<DriverCarData>,
  position: any
): Array<DriverCarData> => {
  cars.sort((carA, carB) => {
    const distanceOfCarA = distanceBetweenLocationsInKm(
      locationFromString(carA?.position!),
      position
    );
    const distanceOfCarB = distanceBetweenLocationsInKm(
      locationFromString(carB?.position!),
      position
    );
    return distanceOfCarA! > distanceOfCarB! ? 1 : -1;
  });
  return cars;
};
