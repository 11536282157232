import { isKYCApp } from 'getaway-data-layer';

export const navigateTo = (history: any, path: string) => {
  if (path === '/map' && isKYCApp()) {
    history.push('/');
  } else {
    history.push(path);
  }
};

export const TALLY_LINKS = {
  FRM_PARTNER_AREA:
    'https://tally.so/embed/3yYJ8x?alignLeft=1&hideTitle=1&transparentBackground=1',
  FRM_NON_PARTNER_REFERRAL:
    'https://tally.so/embed/31MgGL?alignLeft=1&hideTitle=1&transparentBackground=1',
  FRM_PARTNER_REFERRAL:
    'https://tally.so/embed/woGyQx?alignLeft=1&hideTitle=1&transparentBackground=1',
  MVV_EXTRA_FIELDS:
    'https://tally.so/embed/nrdk4N?alignLeft=1&hideTitle=1&transparentBackground=1',
};

export const openLink = (link) => () => {
  if (link) window.open(link, '_blank');
};
